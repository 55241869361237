import React, { Component } from "react";
import "../css/style.css";
import "../css/headernew.scss";
import "../css/how-jama-works.css";

class DropDownMenu extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      showDropDown:false,
      showDropDown1:false
    };
  }
  render(){ 
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <a
        onClick={() => {
          this.setState({
            showDropDown: !this.state.showDropDown,
            showDropDown1: false
          });
        }}
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Company
        <span style={{paddingLeft:3}} ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/dropdown.png" /></span>
      </a>
      {this.state.showDropDown && (
          <div 
            style={{
              position:"absolute",
              top:50,
              right:10,
              padding:10,
              zIndex:10,
             }} class="drop_box">
              <div>
              <a href="https://jamawealth.com/about-us/">About Us</a>
              </div><br />
              <div class="sec_mar">
              <a href="https://jamawealth.com/investment-philosophy/">Investment Philosophy</a>
            </div>
            </div>
        )}
      <a
        onClick={() => {
          this.setState({
            showDropDown1: !this.state.showDropDown1,
            showDropDown: false
          });
        }}
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Equity Advisory 
        <span style={{paddingLeft:3}} ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/dropdown.png" /></span>
      </a>
      {this.state.showDropDown1 && (
          <div 
            style={{
              position:"absolute",
              top:80,
              right:10,
              padding:10,
              zIndex:10,
             }} class="drop_box">
              <div >
              <a href="https://jamawealth.com/spark/">
                <img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22079.png" style={{paddingRight:10}}/>Spark 650</a>
              </div><br />
              <div class="sec_mar" style={{borderTopWidth:1,borderTopColor:"#9192a35c"}}>
               
              <a href="https://jamawealth.com/jewel/" ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22081.png" style={{paddingRight:10}} />Jewel 350</a>
            </div>
            </div>
        )}
      <a
        href="https://performance.jamawealth.com/JewelCompare"
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Performance
      </a>
      <a
        href="https://jamawealth.com/pricing/"
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Pricing
      </a>
      <a
        href="https://jamawealth.com/investor-center/"
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Investor Center
      </a>
      <a
        href="https://jamawealth.com/blog/"
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Insights
      </a>
      <a
        href="https://app.jamawealth.com/"
        class="active navitem dropdown-item right"
        style={{ display: "block" }}
      >
        Sign In
      </a>
      <a
        href="https://jamawealth.com/contact-us/"
        class="header-get-started"
        style={{ alignSelf: "flex-end", marginRight: 15, marginTop: 10 }}
      >
        Get Started
      </a>
    </div>
  );
}}

export default DropDownMenu;