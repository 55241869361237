import React, { Component } from "react";
import jamabrandlogo from "../assets/Jama_wealth_logo.svg";
import { Link, NavLink, BrowserRouter as Router } from "react-router-dom";
import "../css/style.css";
import Sidebar from "./Sidebar";
import { BrowserView, MobileView } from "react-device-detect";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoneNumber: false
    };
  }
  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get("utm_source");
    const medium = params.get("utm_medium");
    const campaign = params.get("utm_campaign");
    if (source && source.length > 0) {
      sessionStorage.setItem("utm_source", source);
    }
    if (medium && medium.length > 0) {
      sessionStorage.setItem("utm_channel", medium);
    }
    if (medium && campaign.length > 0) {
      sessionStorage.setItem("utm_campaign", campaign);
    }
  }

  render() {
    return (
      <div>
        <nav className="row white header-bg-shadow" role="navigation">
          <div
            class="nav-wrapper container-fluid custom-width-header"
            style={{ maxWidth: 1280, margin: "0 auto" }}
          >
            <Sidebar />
            <a className="navbar-brand">
              <Link to="/">
                {" "}
                <img className="brand-logo-img" src={jamabrandlogo} />
              </Link>
            </a>
            <ul className="right hide-on-med-and-down nav-pills">
              {/* UNCOMMENT */}
              <li>
                <a
                  href="https://www.jamawealth.com/investor-center"
                  class="active navitem"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/investor-center"
                  class="active navitem"
                >
                  Our Values
                </a>
                {/* <Link to="/Values" class="active">
                  Our Values
                </Link> */}
              </li>
              <li>
                <a
                  href="https://performance.jamawealth.com/JewelCompare"
                  class="active navitem"
                >
                  Compare
                </a>
                {/* <Link to="/compare" class="active">
                  Compare
                </Link> */}
              </li>
              <li>
                <a href="https://www.jamawealth.com/" class="active navitem">
                  How it works
                </a>
                {/* <Link to="/HowJamaWorksInfo" class="active">
                  How it works
                </Link> */}
              </li>
              <li>
                <a href="https://jamawealth.com/blog/" class="active navitem">
                  Blog
                </a>
                {/* <a href="/blog" target="_blank">
                  Blog
                </a> */}
              </li>
              {/* UNCOMMENT */}
              <li>
                <a
                  href="https://www.jamawealth.com/pricing/"
                  class="active navitem"
                >
                  Pricing
                </a>
                {/* <Link to="/pricing" class="active">
                  Pricing
                </Link> */}
              </li>

              <li>
                <a href="https://www.jamawealth.com/" class="active navitem">
                  Testimonials
                </a>
                {/* <Link to="/testimonial" class="active">
                  Testimonials
                </Link> */}
              </li>
              <li>
                <BrowserView>
                  <div
                    class={""}
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      style={{
                        width: 35,
                        height: 35,
                        // borderRadius: 10,
                        backgroundColor: "transparent",
                        marginTop: 15,
                        borderWidth: 1,
                        borderColor: "#ffc20e",
                        borderStyle: "solid",
                        marginRight: 5,
                        cursor: "pointer",
                        position: "relative"
                      }}
                      src={require("../assets/Landing_Page/call_image.png")}
                      onClick={() => {
                        this.setState({
                          showPhoneNumber: !this.state.showPhoneNumber
                        });
                      }}
                    ></img>
                    {this.state.showPhoneNumber && (
                      <>
                        <div class="triangle-up"></div>
                        <div
                          style={{
                            borderWidth: 1,
                            position: "absolute",
                            backgroundColor: "#ffc20e",
                            height: 30,
                            width: 125,
                            justifyContent: "center",
                            paddingHorizontal: 5,
                            alignItems: "center"
                          }}
                        >
                          <p
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              marginVertical: 0,
                              paddingVertical: 0,
                              // position: "relative",
                              textAlign: "center",
                              marginTop: 5
                            }}
                          >
                            +91&nbsp;987-026-4643
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </BrowserView>
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/app/accounts/login/"
                  class="login-btn"
                >
                  Login
                </a>
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/contact-us/"
                  class="start-btn"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div
          dangerouslySetInnerHTML={{
            __html: `<script>
    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    var source = getParameterByName('utm_source');
    var medium = getParameterByName('utm_medium');
    var campaign = getParameterByName('utm_campaign');
    var parent_source = document.referrer;
    var host = "";

    if(parent_source.length > 0){
        var parts = document.referrer.split('://')[1].split('/');
        var protocol = document.referrer.split('://')[0];
        var host = parts[0];
        host = host.replace("www."," ").replace(".com"," ");
        var pathName = parts.slice(1).join('/')
    }
    if(source.length > 0){
        sessionStorage.setItem("utm_source", source);
    }
    else if(host.length){
        sessionStorage.setItem("utm_source", host);
    }
    if(medium.length > 0){
        sessionStorage.setItem("utm_channel", medium);
    }
    if(campaign.length > 0){
        sessionStorage.setItem("utm_campaign", campaign);
    }
</script>`
          }}
        />
      </div>
    );
  }
}

export default Header;
