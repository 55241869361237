import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const UpstoxRedirection = props => {
  useEffect(() => {
    window.location.replace("https://jamawealth.com/blog/upstox/");
  }, []);
  return null;
};

export default UpstoxRedirection;
