import React, { useEffect } from "react";

const WebexRedirection = props => {
  useEffect(() => {
    window.location.replace(
      "https://simplygrow.webex.com/webappng/sites/simplygrow/meeting/info/6a3cb25886084ae3a7b32132e45d23fb?MTID=m02a9001dc6a5dea65ec4171ad68b21a1"
    );
  }, []);
  return null;
};

export default WebexRedirection;
