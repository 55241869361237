import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const ZerodhaRedirection = props => {
  useEffect(() => {
    window.location.replace("https://jamawealth.com/blog/zerodha/");
  }, []);
  return null;
};

export default ZerodhaRedirection;
