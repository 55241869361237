import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/style.css";
import { Helmet } from "react-helmet";

export default class ThankYou extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Thank You | Jama Wealth Management & Investment Advisory{" "}
          </title>
          <meta
            name="description"
            content="Jama Wealth Management offer a clinical equity portfolio which coupled with both machine learning and human intelligence. Get best returns in stock market with Jama Wealth equity portfolio"
          ></meta>
        </Helmet>
        <div style={{ width: "90%", maxWidth: 1024, margin: "0 auto" }}>
          <div
            style={{
              textAlign: "center",
              marginTop: 100,
              marginBottom: 0
            }}
          >
            <p style={{ fontSize: 22, color: "#121212" }}>
              We have received your information. Please expect the revert soon.
            </p>
            <br />
            <p style={{ fontSize: 21, color: "#121212" }}>
              Thank you for your interest in Jama Wealth. Have a Nice Day!
            </p>
          </div>
        </div>
      </div>
    );
  }
}
