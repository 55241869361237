import React, { Component } from "react";
import "../css/style.css";
import "../css/footer_new.scss";
// import '../css/footer.css';

class FooterNew extends Component {
  render() {
    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="disclaimer">
                <span class="disc_bold">Disclaimer: </span> Any investment
                strategy or portfolio approach performance mentioned excludes
                the impact of transaction costs, taxes, or fees. Dividends are
                assumed as reinvested. Equity investments are subject to HIGH
                MARKET RISK. No liability is assumed. The actual model portfolio
                and backtested portfolio shown above, are based on various
                assumptions and past business performance data and do not
                guarantee or predict future performance. Do not undertake any
                investment without formally discussing with your investment
                advisor.
              </p>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          {/* <section
          style={{
            backgroundColor: "#413aa417",
            paddingTop: 5,
            paddingBottom: 5
          }}
        > */}
          <div
            style={{
              backgroundColor: "#413aa417",
              paddingTop: 5,
            }}
          >
            <img
              class="img-responsive img_foot"
              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png"
              alt="Header"
              style={{
                width: 140,
                height: 40,
              }}
            />
          </div>
          {/* </section> */}
          <footer
            class="text-light"
            style={{
              backgroundColor: "#410DAA",
              // marginTop: 40
            }}
          >
            <div class="footer__big">
              <div class="container">
                <div class="row " style={{ marginTop: 0, paddingTop: 35 }}>
                  <div class="col l3 m6 sm6 xs12">
                    <div class="widget text_widget">
                      <p class="Reach">Reach us at </p>
                      <ul class="icon-list--three">
                        <li class="icon-list2 d-flex ">
                          <i className="material-icons">location_on</i>
                          <div class="contents">
                            <p class="fot_txt">
                              Plot No 7, 2nd Floor, Financial District, My Home
                              Vihanga Road, Gachibowli, Hyderabad, 500032.
                            </p>
                          </div>
                        </li>
                        <li class="icon-list2 d-flex mar_bot">
                          <i className="material-icons">headset</i>
                          <div class="contents ">
                            <p class="fot_txt">
                              <a href="tel:+919870264643" class="white_text">
                                +91 98702 64643
                              </a>
                            </p>
                          </div>
                        </li>
                        <li class="icon-list2 d-flex mar_bot">
                          <i className="material-icons">email</i>
                          <div class="contents">
                            <p class="fot_txt">
                              <a
                                href="mailto:advisor@jamawealth.com"
                                class="white_text"
                              >
                                advisor@jamawealth.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col l3 m6 s6 xs12 d-flex justify-content-lg-center">
                    <div class="widget widget--links text-light">
                      <p class=" fot_hdr">Quick Links</p>
                      <ul class="links ">
                        <li>
                          <a
                            href="https://jamawealth.com/company/#tab7_nav1"
                            class="footer-link"
                          >
                            About Us
                          </a>
                        </li>
                        <li>
                          <a href="https://jamawealth.com/company/#tab7_nav1">
                            Our Team
                          </a>
                        </li>
                        <li>
                          <a href="https://jamawealth.com/investor-center/#tab7_nav2">
                            Testimonials
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://performance.jamawealth.com/JewelCompare"
                            target="_blank"
                          >
                            Performance
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://jamawealth.com/blog/"
                            target="_blank"
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a href="hhttps://jamawealth.com/investor-center/#tab7_nav3">
                            Downloads
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col l3 m6 s6 xs12 d-flex justify-content-lg-center">
                    <div class="widget widget--links ">
                      <p class=" fot_hdr">Information</p>
                      <ul class="links ">
                        <li>
                          <a href="https://www.jamawealth.com/privacy-policy">
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a href="https://www.jamawealth.com/cancellation-policy">
                            Cancellation
                          </a>
                        </li>
                        <li>
                          <a href="https://www.jamawealth.com/terms-and-conditions">
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a href="https://www.jamawealth.com/investor-center">
                            FAQ’s
                          </a>
                        </li>
                        <li>
                          <a href="https://www.jamawealth.com/contact-us">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col l3 m6 s12 xs12">
                    <div class="widget subcribe--widget">
                      <p class="fot_hdr" style={{ color: "#fff" }}>
                        Download our App
                      </p>
                      <div class="row">
                        <div class="col xl6 l6 s12 xs6">
                          <a href="https://apps.apple.com/in/app/jama-wealth-advisory/id1290268362">
                            <img
                              class="img-responsive ima_play"
                              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/sendy/App+Store.png"
                              alt="iOS"
                            />
                          </a>
                        </div>
                        <div class="col xl6 l6 s12 xs6">
                          <a href="https://play.google.com/store/apps/details?id=in.jama.app&hl=en_IN&gl=US">
                            <img
                              class="img-responsive ima_play mar-top"
                              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/sendy/Play+Store.png"
                              alt="Android"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="widget__social">
                        <div class="social  ">
                          <p class="fot_hdr1" style={{ color: "#fff" }}>
                            Follow us
                          </p>
                          <ul class="d-flex flex-wrap">
                            <li>
                              <a
                                href="https://www.facebook.com/jamafunds"
                                class="facebook social-icon-container"
                                style={{}}
                              >
                                <i
                                  class="fa fa-facebook social-icon"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/jamawealth"
                                class="twitter social-icon-container"
                              >
                                <i
                                  class="fa fa-twitter social-icon"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.linkedin.com/company/jamawealth"
                                class="linkedin social-icon-container"
                              >
                                <i
                                  class="fa fa-linkedin social-icon"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.quora.com/q/jamawealth"
                                class="quora social-icon-container"
                              >
                                <i
                                  class="fa fa-quora social-icon"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="footer__small text-center line "
              style={{
                backgroundColor: "#410DAA",
                paddingBottom: 15,
                paddingTop: 15,
              }}
            >
              <p calss="white-light">
                ©2021 Simply Grow Technologies. All rights reserved.
              </p>
            </div>
          </footer>
          <div class="go_top">
            <span class="la la-angle-up"></span>
          </div>
        </div>
      </>
    );
  }
}

export default FooterNew;
