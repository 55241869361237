import React, { Component } from "react";
import "../css/style.css";
import "../css/headernew.scss";
import "../css/how-jama-works.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Header from "./HeaderNew";
import FooterNew from "./FooterNew";
import { Helmet } from "react-helmet";
import HighStock from "highcharts/highstock";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

const crisis = [
  {
    name: "2008 Global Financial Crisis",
    path: "gfc",
  },
  {
    name: "European Crisis",
    path: "europe",
  },
  {
    name: "NBFC Crisis",
    path: "nbfc",
  },
  {
    name: "Post China Yuan Evaluation",
    path: "yuan",
  },
  {
    name: "Taper Tantrum",
    path: "taper",
  },
];

const links = {
  "2008 Global Financial Crisis": "gfc",
  "European Crisis": "europe",
  "Taper Tantrum": "taper",
  "Post China Yuan Evaluation": "yuan",
  "NBFC Crisis": "nbfc",
};

class HowjamaWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      from: null,
      to: null,
      display_from: null,
      all_date: null,
      selectedCrisis: null,
      showDropDown: false,
      sinceInceptionDayCount: null,
      selected: 6,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.redraw != nextState.redraw;
  }

  DispMFbyName = async (name) => {
    var amc_id = "";
    for (var temp = 0; temp < this.state.amc.data.results.length - 1; temp++) {
      if (this.state.amc.data.results[temp]["name"] === name) {
        amc_id = this.state.amc.data.results[temp]["amcfund_id"];
      }
    }
    if (amc_id === "") {
      return;
    }
    if (this.state.data.length > 7) {
      alert("Only 5 Mutual Funds allowed");
      return;
    }
    var MFData = await axios.post(
      "https://docker.jama.co.in/app/api/get_fund_nav_logs/",
      { amcfund_id: amc_id }
    );
    var navs = [];
    for (var temp = 0; temp < MFData.data.results.length - 1; temp++) {
      var datemf = new Date(MFData.data.results[temp]["as_of_date"]);
      navs.push([datemf.getTime(), MFData.data.results[temp]["latest_nav"]]);
    }
    const data = {
      name: name,
      data: navs,
    };
    this.chart.xAxis[0].setExtremes(navs[0][0], navs[navs.length - 1][0]);
    this.setState({
      data: [...this.state.data, data],
    });
    return 0;
  };

  resetSeries = async () => {
    this.setState(
      {
        data: this.state.data.slice(0, 2),
      },
      () => {}
    );
    this.chart.xAxis[0].setExtremes(
      this.state.data[0].data[0][0],
      this.state.data[0].data[this.state.data[0].data.length - 1][0]
    );
  };

  DispPathbyName = async (name) => {
    if (name && Object.keys(links).includes(name))
      window.open(`/crisisCompare2/?crisis=${links[name]}`, "_blank");
  };

  printExtremes = () => {
    try {
      var new_from = new Date(this.chart.xAxis[0].getExtremes().min);
      var new_to = new Date(this.chart.xAxis[0].getExtremes().max);
      if (
        (this.state.from.getTime() + " ").localeCompare(
          this.chart.xAxis[0].getExtremes().min + " "
        ) != 0
      ) {
        this.setState({ from: new_from, to: new_to }, () => {
          this.selectDay(new_from, true);
          this.update_stats(new_to.getTime(), new_from.getTime());
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  indexOfmin(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var min = arr[0];
    var minIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] < min) {
        minIndex = i;
        min = arr[i];
      }
    }
    return [minIndex, min];
  }

  indexOfmax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return [maxIndex, max];
  }

  get_only_navs(data) {
    var onlynav = [];
    for (var i = 0; i < data.length - 1; i++) {
      onlynav.push(data[i][1]);
    }
    return onlynav;
  }

  getMean(data) {
    return (
      data.reduce(function (a, b) {
        return Number(a) + Number(b);
      }) / data.length
    );
  }

  getUrlVars() {
    var vars = {};
    return vars;
  }

  getSD(array, series) {
    var new_data = [];
    if (series == "NIFTY") {
      for (var i = 0; i < array.length; i++) {
        new_data.push(array[i] / 2192.3);
      }
      const n = new_data.length;
      const mean = new_data.reduce((a, b) => a + b) / n;
      return (
        Math.round(
          Math.sqrt(
            new_data.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
              n
          ) * 1000
        ) / 1000
      );
    }

    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;

    return (
      Math.round(
        Math.sqrt(
          array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
        ) * 1000
      ) / 1000
    );
  }

  get_yearly_sd(data, year, to, series) {
    var from = to - year * (1000 * 60 * 60 * 24 * 365);
    var todate = new Date(to);
    var fromdate = new Date(from);
    var today = todate.getDay();
    if (today == 0) {
      to = to - 2 * (1000 * 60 * 60 * 24);
    } else if (today == 6) {
      to = to - 1000 * 60 * 60 * 24;
    }
    var fromday = fromdate.getDay();
    if (fromday == 0) {
      from = from - 2 * (1000 * 60 * 60 * 24);
    } else if (fromday == 6) {
      from = from - 1000 * 60 * 60 * 24;
    }
    var start_index = 0;
    var end_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= from) {
        start_index = j;
        break;
      }
    }

    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= to) {
        end_index = data.length - 1 - j;
        break;
      }
    }
    return this.getSD(
      this.get_only_navs(data.slice(start_index, end_index + 1)),
      series
    );
  }

  get_window(to, from, data) {
    var start = from;
    var end = to;
    var startday = new Date(from);
    var endday = new Date(to);
    startday = startday.getDay();
    endday = endday.getDay();
    if ((startday == 0) | (startday == 6)) {
      start = start + 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 0) {
      end = end - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      end = end - 1 * (1000 * 60 * 60 * 24);
    }
    var from_index = 0;
    var to_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= end) {
        to_index = data.length - 1 - j;
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start) {
        from_index = j;
        break;
      }
    }
    return data.slice(from_index, to_index + 1);
  }

  get_cagr(data) {
    var days = (data[data.length - 1][0] - data[0][0]) / (1000 * 60 * 60 * 24);
    var jars_cagr =
      Math.pow(data[data.length - 1][1] / data[0][1], 365 / days) - 1;
    return Math.round(jars_cagr * 100 * 100) / 100 + "%";
  }

  get_year_returns_from_to(data, fromdate, date) {
    const no_of_years = (date - fromdate) / (1000 * 60 * 60 * 24 * 365);
    var start_index = 0;
    var end_index = data.length - 1;

    for (var j = 0; j < data.length; j++) {
      if (data[j][0] > fromdate) {
        start_index = j;
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= date) {
        end_index = data.length - 1 - j;
        break;
      }
    }
    const start_nav = data[start_index][1];
    const end_nav = data[end_index][1];
    const annaualised = Math.pow(end_nav / start_nav, 1 / no_of_years) - 1;
    if (no_of_years > 1) {
      return (Math.round(annaualised * 100 * 100) / 100).toFixed(1) + "% p.a";
    } else {
      return (
        (Math.round((end_nav / start_nav - 1) * 100 * 100) / 100).toFixed(1) +
        "% abs"
      );
    }
  }

  get_year_returns(data, years, date) {
    var start = date - years * 1000 * 60 * 60 * 24 * 365;
    var startday = new Date(start);
    var endday = new Date(date);
    startday = startday.getDay();
    endday = endday.getDay();
    var start_index = 0;
    var end_index = data.length - 1;
    if ((startday == 0) | (startday == 6)) {
      start = start + 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 0) {
      date = date - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      date = date - 1 * (1000 * 60 * 60 * 24);
    }
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start) {
        start_index = j;
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= date) {
        end_index = data.length - 1 - j;
        break;
      }
    }
    const start_nav = data[start_index][1];
    const end_nav = data[end_index][1];
    const annaualised = Math.pow(end_nav / start_nav, 1 / years) - 1;

    return (Math.round(annaualised * 100 * 100) / 100).toFixed(1) + "%";
  }

  get_year_cagr(data, years, from, to) {
    if (to - from >= years * 1000 * 60 * 60 * 24 * 365) {
      var start = to - years * 1000 * 60 * 60 * 24 * 365;
      var yearcagr = this.get_cagr(this.get_window(to, start, data));
      return yearcagr;
    } else {
      return "-";
    }
  }

  init_stats(todate, fromdate) {
    window.stats = {
      redraw: 0,
      nifty_cagr: 0,
      jars_cagr: 0,
      jars_1yr_returns: 0,
      jars_3yr_returns: 0,
      jars_5yr_returns: 0,
      jars_10yr_returns: 0,
      nifty_1yr_returns: 0,
      nifty_3yr_returns: 0,
      nifty_5yr_returns: 0,
      nifty_10yr_returns: 0,
      jars1yr_sd: 0,
      jars3yr_sd: 0,
      jars5yr_sd: 0,
      jars10yr_sd: 0,
      nifty1yr_sd: 0,
      nifty3yr_sd: 0,
      nifty5yr_sd: 0,
      nifty10yr_sd: 0,
    };
  }

  update_stats_new(todate, fromdate) {
    window.stats = {
      redraw: 0,
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_from_to: this.get_year_returns_from_to(
        this.get_window(todate, fromdate, this.state.data[1].data),
        fromdate,
        todate
      ),
      nifty_from_to: this.get_year_returns_from_to(
        this.get_window(todate, fromdate, this.state.data[0].data),
        fromdate,
        todate
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
      jars_1yr_returns: this.get_year_returns(
        this.state.data[1].data,
        1,
        todate
      ),
      jars_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        3,
        todate
      ),
      jars_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        5,
        todate
      ),
      jars_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        10,
        todate
      ),
      nifty_1yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        1,
        todate
      ),
      nifty_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        3,
        todate
      ),
      nifty_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        5,
        todate
      ),
      nifty_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        10,
        todate
      ),
      jars1yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        1,
        todate,
        "JARS"
      ),
      jars3yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        3,
        todate,
        "JARS"
      ),
      jars5yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        5,
        todate,
        "JARS"
      ),
      jars10yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        10,
        todate,
        "JARS"
      ),
      nifty1yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        1,
        todate,
        "NIFTY"
      ),
      nifty3yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        3,
        todate,
        "NIFTY"
      ),
      nifty5yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        5,
        todate,
        "NIFTY"
      ),
      nifty10yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        10,
        todate,
        "NIFTY"
      ),
    };
    this.forceUpdate();
  }

  update_stats(todate, fromdate) {
    this.setState({
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
      jars_from_to: this.get_year_returns_from_to(
        this.state.data[1].data,
        fromdate,
        todate
      ),
      nifty_from_to: this.get_year_returns_from_to(
        this.state.data[0].data,
        fromdate,
        todate
      ),
      jars_1yr_returns: this.get_year_cagr(
        this.state.data[1].data,
        1,
        fromdate,
        todate
      ),
      jars_3yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data),
        3,
        fromdate,
        todate
      ),
      jars_5yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data),
        5,
        fromdate,
        todate
      ),
      jars_10yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data),
        10,
        fromdate,
        todate
      ),
      nifty_1yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data),
        1,
        fromdate,
        todate
      ),
      nifty_3yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data),
        3,
        fromdate,
        todate
      ),
      nifty_5yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data),
        5,
        fromdate,
        todate
      ),
      nifty_10yr_returns: this.get_year_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data),
        10,
        fromdate,
        todate
      ),
      jars1yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        1,
        todate,
        "JARS"
      ),
      jars3yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        3,
        todate,
        "JARS"
      ),
      jars5yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        5,
        todate,
        "JARS"
      ),
      jars10yr_sd: this.get_yearly_sd(
        this.state.data[1].data,
        10,
        todate,
        "JARS"
      ),
      nifty1yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        1,
        todate,
        "NIFTY"
      ),
      nifty3yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        3,
        todate,
        "NIFTY"
      ),
      nifty5yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        5,
        todate,
        "NIFTY"
      ),
      nifty10yr_sd: this.get_yearly_sd(
        this.state.data[0].data,
        10,
        todate,
        "NIFTY"
      ),
      redraw: this.state.redraw + 1,
    });
  }

  handleFromChange = (day) => {
    if (Object.prototype.toString.call(day) != "[object Date]") {
      return 0;
    }
    if (day >= this.state.to) {
      alert("From Date must be lesser than To Date");
      return 0;
    }
    var f_date = new Date(day);
    f_date.setDate(day.getDate() - 2);
    this.setState({ from: f_date, display_from: day }, () => {
      this.selectDay(f_date, true);
      this.calculateCagrs(this.state.to, this.state.from - 23400000);
      if (this.state.to && this.state.from) {
        this.update_stats(this.state.to, this.state.from - 23400000);
      }
    });
  };

  handleToChange = (day) => {
    if (day <= this.state.from) {
      alert("To Date must be greater than From Date");
      return 0;
    }
    this.setState({ to: day, display_to: day }, () => {
      this.selectDay(day);
      this.calculateCagrs(this.state.to - 23400000, this.state.from - 23400000);
      this.update_stats(this.state.to - 23400000, this.state.from - 23400000);
    });
  };

  selectDay = (time, isMin) => {
    let timestamp;
    if (time) {
      timestamp = time.getTime();
    } else {
      if (isMin) {
        timestamp = this.state.from;
      } else {
        timestamp = this.state.to;
      }
    }
    const xAxis = this.chart.xAxis[0];

    xAxis.setExtremes(
      isMin ? timestamp : xAxis.min,
      isMin ? xAxis.max : timestamp
    );
    this.setState(isMin ? { minInput: false } : { maxInput: false });
  };

  onChange = (date) => this.setState({ date });
  async componentDidMount() {
    let sinceInceptionDayCount = moment(
      new Date(Date.now() - 3600 * 1000 * 24)
    ).diff(moment("2021-06-01"), "days", true);
    this.setState({ sinceInceptionDayCount });

    const jars = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/spark_nav_values.json"
    );

    const nifty = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty_nav_values.json"
    );
    const nifty50TRI = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/bse_smlcap_nav_values.json"
    );
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    const amcfunds = await axios.get(
      "https://docker.jama.co.in/app/api/get_equity_amcfunds/",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );

    const getDates = nifty.data;

    this.setState({
      data: [
        {
          name: "BSE Smallcap",
          data: nifty50TRI.data,
          color: "red",
        },
        {
          name: "SPARK",
          data: jars.data,
          color: "#410Daa",
          id: "JAMA",
        },
      ],
      amc: amcfunds,
      from: new Date(moment().subtract(36, "months")),
      display_from: new Date(moment().subtract(36, "months")),
      all_date: new Date(jars.data[0][0]),
      to: new Date(getDates.pop()[0]),
      display_to: new Date(nifty.data[0][0]),
      nifty_cagr: this.get_cagr(nifty.data),
      jars_cagr: this.get_cagr(jars.data),
      redraw: 1,
    });
    if (this.state.to && this.state.from) {
      this.update_stats(this.state.to.getTime(), this.state.from.getTime());
    }
  }

  calculateCagrs = (todate, fromdate) => {
    this.setState({
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
    });
  };

  buttonClick = (value) => {
    let newDate;
    if (value == -1) {
      this.setState({ selected: 4 });
      newDate = new Date(new Date().getFullYear(), 0, 1);
    } else {
      this.setState({ selected: "" });
      if (value == 0) {
        newDate = this.state.all_date;
      } else {
        if (value == "SI") {
          newDate = moment("2021-06-01");
          this.setState({ selected: 11 });
        } else {
          newDate = moment().subtract(value, "months");
        }
      }
    }

    this.setState(
      {
        from: new Date(newDate),
        display_from: new Date(newDate),
      },
      () => {
        this.calculateCagrs(this.state.to, this.state.from);
      }
    );
  };

  render() {
    var yearly_dict = [9, 5, 9, 6, 9, 7, 9, 8, 9, 9, 9];
    var monthly_dict = [9, 0, 9, 1, 9, 9, 2, 9, 9, 3, 9, 9, 5];
    var range_dict = yearly_dict;
    var yearly_range = this.getUrlVars()["years"];
    var default_range = yearly_range;
    var month_range = this.getUrlVars()["months"];
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    if (month_range > 0) {
      range_dict = monthly_dict;
      default_range = month_range;
    }

    const { to, display_from } = this.state;
    this.init_stats(this.state.to, this.state.from);

    const compareOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        renderTo: "container",
        height: 400,
        zIndex: -1,
        events: {
          redraw: () => {
            this.printExtremes();
          },
        },
      },
      exporting: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        selected:
          this.state.selected != ""
            ? this.state.selected
            : range_dict[default_range],
        enabled: true,
        inputEnabled: false,
        verticalAlign: "bottom",
        buttonTheme: {
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 8,
          style: {
            color: "#039",
            fontWeight: "bold",
          },
          states: {
            hover: {},
            select: {
              fill: "#039",
              style: {
                color: "white",
              },
            },
          },
        },
        inputBoxBorderColor: "gray",
        inputBoxWidth: 120,
        inputBoxHeight: 18,
        inputStyle: {
          color: "#039",
          fontWeight: "bold",
        },
        labelStyle: {
          color: "silver",
          fontWeight: "bold",
        },
        buttons: [
          {
            type: "month",
            count: 1,
            text: "1m",
            events: {
              click: () => this.buttonClick(1),
            },
          },
          {
            type: "month",
            count: 3,
            text: "3m",
            events: {
              click: () => this.buttonClick(3),
            },
          },
          {
            type: "month",
            count: 6,
            text: "6m",
            events: {
              click: () => this.buttonClick(6),
            },
          },
          {
            type: "month",
            count: 9,
            text: "9m",
            events: {
              click: () => this.buttonClick(9),
            },
          },
          {
            type: "ytd",
            text: "YTD",
            events: {
              click: () => this.buttonClick(-1),
            },
          },
          {
            type: "year",
            count: 1,
            text: "1y",
            events: {
              click: () => this.buttonClick(12),
            },
          },
          {
            type: "year",
            count: 3,
            text: "3y",
            events: {
              click: () => this.buttonClick(36),
            },
          },
          {
            type: "year",
            count: 5,
            text: "5y",
            events: {
              click: () => this.buttonClick(60),
            },
          },
          {
            type: "year",
            count: 7,
            text: "7y",
            events: {
              click: () => this.buttonClick(84),
            },
          },
          {
            type: "year",
            count: 10,
            text: "10y",
            events: {
              click: () => this.buttonClick(120),
            },
          },
          {
            type: "all",
            text: "All",
            events: {
              click: () => this.buttonClick(0),
            },
          },
          {
            type: "day",
            count: this.state.sinceInceptionDayCount,
            text: "S.I.",
            events: {
              click: () => this.buttonClick("SI"),
            },
          },
        ],
      },
      yAxis: {
        labels: {
          align: "left",
          formatter: function () {
            return (this.value > 0 ? " + " : "") + this.value + "%";
          },
        },
        plotLines: [
          {
            value: 0,
            width: 2,
            color: "silver",
          },
        ],
      },
      xAxis: {
        events: {
          setExtremes: function () {},
        },
      },
      plotOptions: {
        series: {
          compare: "percent",
          showInNavigator: true,
          compareStart: true,
        },
        flags: {
          height: 30,
        },
      },
      lang: {
        thousandsSep: ",",
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color};font-weight:700;">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
        valueDecimals: 2,
        split: true,
      },
      series: this.state.data,
    };

    return (
      <div>
        <Header />
        <Helmet>
          <title>
            {" "}
            Best Equity Investments | Jama Wealth Advisory SEBI RIA{" "}
          </title>
          <meta
            name="description"
            content="Jama Wealth Equity Advisory has beaten the Nifty50 index across time periods. It has stood the test of time in various bear markets - Covid, GFC etc"
          ></meta>
        </Helmet>
        <script>this.update_stats_new(this.state.to,this.state.from)</script>
        <div
          class="jars-section-title-heading"
          style={{ margin: "80px auto 0", textAlign: "center" }}
        >
          <h1 style={{ fontSize: 36 }}>Compare SPARK with BSE Smallcap</h1>
        </div>

        <div className="compareTableGraph">
          <div
            style={{ width: "90%", float: "left", margin: "0 auto" }}
            className="secondaryWrapper"
          >
            {this.state.data && (
              <table
                id="stats_table"
                style={{
                  alignItems: "flex-end",
                  margintop: "auto",
                  padding: 50,
                }}
              >
                <tr
                  style={{
                    paddingBottom: 8,
                  }}
                >
                  <td
                    style={{
                      padding: "0 0 8px 0",
                      color: "#410DAA",
                      width: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        margin: "0 auto",
                        maxWidth: 400,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          float: "right",
                          marginRight: 10,
                        }}
                      >
                        <p
                          style={{
                            marginRight: 8,
                            color: "#bbb",
                            marginBottom: 5,
                            paddingBottom: 0,
                          }}
                        >
                          From
                        </p>
                        <DayPickerInput
                          style={{
                            border: "1px solid #ddd",
                            width: 100,
                            padding: 4,
                            borderRadius: 8,
                          }}
                          inputProps={{
                            style: {
                              border: "none",
                              height: "auto",
                              margin: 0,
                              textAlign: "center",
                              fontSize: 12,
                            },
                          }}
                          onDayChange={this.handleFromChange}
                          value={display_from}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            marginRight: 8,
                            color: "#bbb",
                            marginLeft: 0,
                            marginBottom: 5,
                            paddingBottom: 0,
                          }}
                        >
                          To
                        </p>
                        <DayPickerInput
                          style={{
                            border: "1px solid #ddd",
                            width: 100,
                            padding: 4,
                            borderRadius: 8,
                          }}
                          inputProps={{
                            style: {
                              border: "none",
                              height: "auto",
                              margin: 0,
                              textAlign: "center",
                              fontSize: 12,
                            },
                            readonly: true,
                          }}
                          onDayChange={this.handleToChange}
                          value={to}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      padding: "0px 0 8px 0",
                      color: "#410DAA",
                      alignItems: "flex-start",
                      width: "25%",
                      verticalAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "#bbb",
                        marginBottom: 15,
                        paddingBottom: 0,
                      }}
                    >
                      SPARK
                    </p>
                    <p>{this.state.jars_from_to}</p>
                  </td>
                  <td
                    style={{
                      padding: "0px 0 8px 0",
                      color: "#121212",
                      alignItems: "center",
                      width: "25%",
                      verticalAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "#bbb",
                        marginBottom: 15,
                        paddingBottom: 0,
                      }}
                    >
                      BSE Smallcap
                    </p>
                    <p>{this.state.nifty_from_to}</p>
                  </td>
                </tr>
              </table>
            )}
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 700,
                float: "right",
              }}
              className="mobileGraphWrapper"
            >
              <div
                style={{ float: "right", display: "none" }}
                className="crisisContainer"
              >
                <h7
                  style={{
                    color: "#121212",
                    fontWeight: "bold",
                    float: "right",
                  }}
                >
                  <b>Previous Bear Markets: </b>
                </h7>

                <div
                  style={{
                    width: 200,
                    border: "1px solid #410daa",
                    borderRadius: 8,
                    height: 36,
                    position: "relative",
                  }}
                  onClick={() =>
                    this.setState({
                      showDropDown: !this.state.showDropDown,
                    })
                  }
                >
                  <p style={{ height: 24 }}>{this.state.selectedCrisis}</p>

                  {this.state.showDropDown && (
                    <div
                      style={{
                        zIndex: 100,
                        position: "absolute",
                        backgroundColor: "grey",
                        color: "#fff",
                        borderRadius: 8,
                      }}
                    >
                      {crisis.map((crisisItem) => {
                        return (
                          <p
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                selectedCrisis: crisisItem.name,
                              });
                              this.DispPathbyName(crisisItem.name);
                            }}
                          >
                            {crisisItem.name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <br></br>
              <br></br>

              {this.state.data ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 32,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16,
                      }}
                    >
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "#410daa",
                          marginRight: 8,
                        }}
                      ></div>
                      <p>SPARK</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "red",
                          marginRight: 8,
                        }}
                      ></div>
                      <p>BSE Smallcap</p>
                    </div>
                  </div>
                  <HighchartsReact
                    style={{ heigh: 1000, zIndex: -1 }}
                    ref={"chart"}
                    highcharts={HighStock}
                    constructorType={"stockChart"}
                    callback={(chart) => {
                      if (window.innerWidth <= 850) {
                        this.chart = chart;
                      }
                    }}
                    options={{
                      ...compareOptions,
                    }}
                  />
                </>
              ) : (
                <div
                  style={{
                    width: 500,
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div class={"loader"}></div>
                </div>
              )}
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5,
                }}
                class=" research-invest-btn-section "
              >
                <Link style={{ width: "85%" }} to="/contactus">
                  <a
                    style={{
                      float: "center",
                      borderRadius: "10px!important",
                      backgroundColor: "#410DAA",
                      color: "#fff !important",
                    }}
                    class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                  >
                    <span style={{ color: "#fff !important" }}>
                      Request Callback
                    </span>
                  </a>
                </Link>
              </div>
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    width: "84%",
                    color: "#410daa",
                    justifyContent: "center",
                    float: "center",
                    display: "flex",
                  }}
                >
                  Get a free health check on stock portfolio from advisor.
                </p>
              </div>
            </div>
            <div
              style={{
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16,
              }}
              className="crisisTable"
            >
              <table
                id="stats_table"
                style={{
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto",
                  padding: 50,
                }}
              >
                <tr
                  style={{
                    height: "40%",
                    borderBottom: "2px solid #ddd",
                    paddingBottom: 8,
                  }}
                >
                  <td style={{ padding: "0 0 8px 0", color: "#410DAA" }}>
                    Annualized Return
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>SPARK</td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    BSE Smallcap
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">1 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_1yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_1yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_1yr">
                    {this.state.nifty_1yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">3 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_3yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_3yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_3yr">
                    {this.state.nifty_3yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">5 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_5yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_5yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">10 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_10yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_10yr_returns}
                  </td>
                </tr>
              </table>
            </div>
            <br />

            <div>
              <Link to="/SparkCompareMF" target="_blank" class="btn-tools">
                <a>Compare Mutual Funds Performance with SPARK</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/SparkCrisisCompare" target="_blank" class="btn-tools">
                <a>Compare Previous Bear Market with SPARK</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/SparkSIP" target="_blank" class="btn-tools">
                <a style={{ borderBottom: "none" }}>
                  Compare SIP Performance of SPARK vs BSE Smallcap
                </a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
              class=" research-invest-btn-section mobCTA"
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important",
                    backgroundColor: "#410DAA",
                    color: "#fff !important",
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                >
                  Request Callback
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobCTA"
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex",
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              margin: "0 auto",
              maxWidth: 700,
              float: "right",
            }}
            className="graphWrapper"
          >
            <div
              style={{ float: "right", display: "none" }}
              className="crisisContainer"
            >
              <h7
                style={{ color: "#121212", fontWeight: "bold", float: "right" }}
              >
                <b>Previous Bear Markets: </b>
              </h7>

              <div
                style={{
                  width: 200,
                  border: "1px solid #410daa",
                  borderRadius: 8,
                  height: 36,
                  position: "relative",
                }}
                onClick={() =>
                  this.setState({
                    showDropDown: !this.state.showDropDown,
                  })
                }
              >
                <p style={{ height: 24 }}>{this.state.selectedCrisis}</p>

                {this.state.showDropDown && (
                  <div
                    style={{
                      zIndex: 100,
                      position: "absolute",
                      backgroundColor: "grey",
                      color: "#fff",
                      borderRadius: 8,
                    }}
                  >
                    {crisis.map((crisisItem) => {
                      return (
                        <p
                          style={{ color: "#fff", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              selectedCrisis: crisisItem.name,
                            });
                            this.DispPathbyName(crisisItem.name);
                          }}
                        >
                          {crisisItem.name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <br></br>
            <br></br>

            {this.state.data ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 32,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 16,
                    }}
                  >
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "#410daa",
                        marginRight: 8,
                      }}
                    ></div>
                    <p>SPARK</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "red",
                        marginRight: 8,
                      }}
                    ></div>
                    <p>BSE Smallcap</p>
                  </div>
                </div>
                <HighchartsReact
                  style={{ heigh: 1000, zIndex: -1 }}
                  ref={"chart"}
                  highcharts={HighStock}
                  constructorType={"stockChart"}
                  callback={(chart) => {
                    if (window.innerWidth > 850) {
                      this.chart = chart;
                    }
                  }}
                  options={{
                    ...compareOptions,
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  width: 500,
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div class={"loader"}></div>
              </div>
            )}
            <p style={{ marginLeft: 9, fontSize: 12, color: "gray" }}>
              Note: S.I. = Since Inception
            </p>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
              class=" research-invest-btn-section "
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important",
                    backgroundColor: "#410DAA",
                    color: "#fff !important",
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                >
                  Request Callback
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex",
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>
        </div>
        <div class=" research-invest-section ">
          <div class=" row ">
            <div class=" col s12 m6 offset-m3 ">
              <div
                class=" research-invest-section-content "
                style={{ paddingTop: 0 }}
              ></div>
            </div>
          </div>
        </div>
        <FooterNew />
      </div>
    );
  }
}

export default HowjamaWorks;
