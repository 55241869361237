import React, { Component } from "react";
import "../css/style.css";
import "../css/how-jama-works.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Moneybag from "../assets/Money Bag.svg";
import magicwand from "../assets/magic-wand.svg";
import allocate from "../assets/allocate.svg";
import Howjamaimg from "../assets/Howjamaimg.svg";
import bars from "../assets/bars.svg";
import Header from "./HeaderNew";
import jamabrandlogo from "../assets/Jama_wealth_logo.svg";

// import HomepageBodyContent from "./HomepageBodyContent";
import FooterNew from "./FooterNew";
import philosophyyllo from "../assets/philosophyyllo.png";
import howjamaimgblk from "../assets/howjamaimgblk.png";
import startimgyllo from "../assets/startimgyllo.png";
// import WOW from "wowjs";
import Introsection from "./Introsection";
import FeatureSection from "./featureSection";
import { Helmet } from "react-helmet";
import HighStock from "highcharts/highstock";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

class HowjamaWorks extends Component {
  state = {
    total_amount: 0
  };

  async componentDidMount() {
    // new WOW.WOW().init();
    // window.scrollTo(0, 0);

    const jars = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/jars_nav_values.json"
    );

    const nifty = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty_nav_values.json"
    );
    const nifty50TRI = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty50tri_nav_values.json"
    );
    const nifty50 = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty50_nav_values.json"
    );
    const amcfunds = await axios.get(
      "https://docker.jama.co.in/app/api/get_equity_amcfunds/",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    );
    var amcfundnames = [];
    var dropdown = document.getElementById("amcdatalist");
    // console.log(amcfunds.data.results);
    amcfunds.data.results.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    for (var funds = 0; funds < amcfunds.data.results.length - 1; funds++) {
      var el = document.createElement("option");
      el.textContent = amcfunds.data.results[funds]["name"];
      el.value = amcfunds.data.results[funds]["name"];
      dropdown.appendChild(el);
    }
    var xirr = require("xirr");
    var rate = xirr([
      { amount: -1000, when: new Date(2016, 0, 15) },
      { amount: -2500, when: new Date(2016, 1, 8) },
      { amount: -1000, when: new Date(2016, 3, 17) },
      { amount: 5050, when: new Date(2016, 7, 24) }
    ]);
    // console.log(rate);

    this.setState({
      data: [
        {
          name: "Nifty 50",
          data: nifty50TRI.data,
          color: "red"
        },
        {
          name: "Jamā Wealth",
          data: jars.data,
          color: "#410Daa",
          id: "JAMA"
        },
        // {
        //   name: "Nifty 50",
        //   data: nifty50.data,
        //   // color: 'transparent',
        //   lineColor: "transparent",
        // },
        // {
        //   name: "Jamā Deployment",
        //   type: "flags",
        //   data: [
        //     {
        //       x: Date.UTC(2019, 8, 22),
        //       title: "JARS Deployment",
        //       text: "From today JARS was deployed in the market",
        //     },
        //   ],
        //   onSeries: "JAMA",
        //   shape: "sqaurepin",
        //   width: 50,
        //   height: 20,
        // },
        {
          name: "Eurpean Crisis",
          type: "flags",
          data: [
            {
              x: Date.UTC(2011, 12, 20),
              title: "JARS Deployment",
              text: "From today JARS was deployed in the market"
            }
          ],
          onSeries: "JAMA",
          shape: "sqaurepin",
          width: 50,
          height: 20,
          visible: false
        }
      ],
      amc: amcfunds

      // from: moment(nifty.data[0][0]),
      // to: moment(getDates.pop()[0])
    });
    this.init_investment_stats(100000, 3);
    // this.update_stats_new(this.state.to.getTime(), this.state.from.getTime());
  }

  investment_stats = e => {
    // console.log("E is:  " + e);
    e.preventDefault();
    var total_amount = e.target[0].value * e.target[1].value * 12;
    var jars_values = this.sip_value(
      this.state.data[1].data,
      e.target[1].value,
      parseInt(e.target[0].value)
    );
    var nifty_values = this.sip_value(
      this.state.data[0].data,
      e.target[1].value,
      parseInt(e.target[0].value)
    );
    this.setState({
      total_invesment: jars_values[0],
      jars_total_amount: jars_values[1],
      nifty_total_amount: nifty_values[1],
      jars_xirr: jars_values[2],
      nifty_xirr: nifty_values[2],
      jars_abs_growth: jars_values[3],
      nifty_abs_growth: nifty_values[3]
    });
  };
  init_investment_stats(m_amount, s_years) {
    var total_amount = m_amount * s_years * 12;
    var jars_values = this.sip_value(
      this.state.data[1].data,
      s_years,
      m_amount
    );
    var nifty_values = this.sip_value(
      this.state.data[0].data,
      s_years,
      m_amount
    );
    this.setState({
      total_invesment: jars_values[0],
      jars_total_amount: jars_values[1],
      nifty_total_amount: nifty_values[1],
      jars_xirr: jars_values[2],
      nifty_xirr: nifty_values[2],
      jars_abs_growth: jars_values[3],
      nifty_abs_growth: nifty_values[3]
    });
  }
  monthly_growth(data, year, month, duration) {
    var start_month = new Date(year, month, 1);
    var start_month_time = start_month.getTime();
    var end_month = new Date(year, (start_month.getMonth() % 12) + 1, 1);
    var end_month_time = end_month.getTime();
    // console.log(end_month + "is" + end_month_time);
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start_month_time) {
        var start_index = j;
        // console.log("Start Index: " + data[start_index][1]);
        break;
      }
    }
    for (var k = 0; k < data.length; k++) {
      if (data[k][0] >= end_month_time) {
        var end_index = k;
        // console.log("Start Index: " + data[end_index][1]);
        break;
      }
    }
    var growth = data[end_index][1] / data[start_index][1] - 1;
    // console.log("Month Growth is: " + (growth*100) + "%");
    return growth;
  }
  sip_value(data, years, monthly_amount) {
    var today = new Date();
    var temp_date = new Date(today.getFullYear() - years, today.getMonth(), 1);
    var total_amount = 0;
    var present_year = temp_date.getFullYear();
    var present_month = temp_date.getMonth();
    var xirr = require("xirr");
    var xirr_values = [];
    // console.log("TEMP DATE IS" + temp_date);
    while (today > temp_date) {
      if (present_month == 0) {
        if (present_year != temp_date.getFullYear()) {
          // console.log("Present Year" + present_year);
          // console.log("Temp Date year" + temp_date.getFullYear());
          present_year += 1;
        }
      }
      var m_growth = this.monthly_growth(data, present_year, present_month, 1);
      xirr_values.push({
        amount: -monthly_amount,
        when: new Date(present_year, present_month, 1)
      });
      present_month += 1;
      // console.log("present month: " + present_month);
      temp_date = new Date(present_year, present_month + 1, 5);
      if (present_month == 12) {
        present_month = 0;
      }
      total_amount = total_amount + monthly_amount;
      total_amount = total_amount * (1 + m_growth);
      // console.log("CURRENT AMOUNT IS: " + total_amount);
    }
    // console.log("THE FINAL AMOUNT IS: " + total_amount);
    xirr_values[xirr_values.length - 1].amount = -10000;
    xirr_values.push({
      amount: total_amount,
      when: new Date(today.getFullYear(), today.getMonth(), 1)
    });
    var calc_xirr = xirr(xirr_values);
    // console.log("XIRR is: " + calc_xirr);
    // console.log(xirr_values);
    var invested_amount = monthly_amount * years * 12;
    // console.log("INVESTED AMOUNT is: " + invested_amount);
    var absolute_gain = total_amount / invested_amount - 1;
    return [
      invested_amount.toLocaleString(),
      Math.round(total_amount).toLocaleString(),
      Math.round(calc_xirr * 100 * 100) / 100 + "%",
      Math.round(absolute_gain * 100 * 100) / 100 + "%"
    ];
  }

  DispMFbyName = async name => {
    var amc_id = "";
    for (var temp = 0; temp < this.state.amc.data.results.length - 1; temp++) {
      if (this.state.amc.data.results[temp]["name"] === name) {
        // console.log("ID is: ", this.state.amc.data.results[temp]["amcfund_id"]);
        amc_id = this.state.amc.data.results[temp]["amcfund_id"];
        // break;
        // return this.state.amc.data.results[temp]["amcfund_id"];
      }
      // return;
    }
    if (amc_id === "") {
      return;
    }
    this.resetSeries();

    if (this.state.data.length > 7) {
      alert("Only 5 Mutual Funds allowed");
      return;
    }
    var MFData = await axios.post(
      "https://docker.jama.co.in/app/api/get_fund_nav_logs/",
      { amcfund_id: amc_id }
    );
    // console.log("type of amc_id is:", typeof amc_id);
    // console.log("MF DATA IS:", MFData);
    var tnavs = [];
    for (var temp = 0; temp < MFData.data.results.length; temp++) {
      var datemf = new Date(MFData.data.results[temp]["as_of_date"]);
      tnavs.push([datemf.getTime(), MFData.data.results[temp]["latest_nav"]]);
      tnavs.sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0));
    }
    // console.log(tnavs);
    const tdata = {
      name: name,
      data: tnavs,
      color: "black"
    };
    // this.chart.xAxis[0].setExtremes(navs[0][0],navs[navs.length-1][0]);
    this.setState({
      data: [...this.state.data, tdata]
    });
    return 0;
  };
  resetSeries = async () => {
    var serieslen = this.chart.series.length - 2;
    this.setState({
      data: this.state.data.slice(0, 2)
    });
    this.chart.xAxis[0].getExtremes();
    // console.log(this.chart.xAxis[0].getExtremes());
    // this.chart.xAxis[0].setExtremes(this.state.data[0].data[0][0],this.state.data[0].data[this.state.data[0].data.length-1][0]);
    this.setState({
      from: new Date(this.chart.xAxis[0].getExtremes().min),
      to: new Date(this.chart.xAxis[0].getExtremes().max)
    });
    this.chart.xAxis[0].getExtremes();
    // console.log(this.chart.xAxis[0].getExtremes());
    this.setState({
      fund_1yr_returns: "-",
      fund_3yr_returns: "-",
      fund_5yr_returns: "-",
      fund_10yr_returns: "-"
    });
  };

  render() {
    return (
      <div className="compareWrapper">
        <Header />
        <Helmet>
          <title>
            {" "}
            SIP Calculator | Jama Wealth Equity Long Term Portfolio{" "}
          </title>
          <meta
            name="description"
            content="Jama Wealth Long Term Equity offers the best SIP Investment Plans. Check the SIP Calculator to help plan your monthly investments."
          ></meta>
        </Helmet>
        <div
          class="jars-section-title-heading"
          style={{ margin: "80px auto 0", textAlign: "center" }}
        >
          <h1 style={{ fontSize:36}} >Jama Wealth Equity Long - SIP Performance</h1>
        </div>
        <div className="compareTableGraph">
          <div>
            <datalist id="amcdatalist"></datalist>

            <form
              onSubmit={this.investment_stats}
              name="SIPform"
              style={{
                float: "centre",
                alignItems: "center",
                margintop: "auto",
                padding: 50,
                marginTop: 70
              }}
            >
              <label
                style={{
                  color: "black",
                  fontSize: 15
                }}
              >
                Monthly Investment
                <input
                  style={{
                    border: "1px solid #ddd",
                    width: "100%",
                    borderRadius: 8,
                    paddingLeft: 8
                  }}
                  type="number"
                  name="monthly"
                  defaultValue="100000"
                  min="30000"
                />
              </label>
              <label
                style={{
                  color: "black",
                  fontSize: 15
                }}
              >
                Years
                <input
                  style={{
                    border: "1px solid #ddd",
                    width: "100%",
                    borderRadius: 8,
                    paddingLeft: 8
                  }}
                  type="number"
                  name="Years"
                  defaultValue="3"
                  min="1"
                />
              </label>
              <input
                style={{
                  float: "centre",
                  alignItems: "center",
                  marginTop: 50,
                  width: "100%",
                  borderRadius: "10px",
                  padding: 20,
                  border: "0",
                  background: "#410DAA",
                  color: "#fff"
                }}
                type="submit"
                value="Update Performance"
              />
            </form>
            <br />
            <div className="regular-tools">
              <Link to="/JewelCompare" target="_blank" className="btn-tools">
                <a>Compare JWEL with Nifty</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/comparemf2" target="_blank" className="btn-tools">
                <a>Compare Mutual Funds Performance with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/crisiscompare2" target="_blank" className="btn-tools">
                <a>Compare Previous Bear Market with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
            </div>
            <br />
            <br />
          </div>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              float: "center"
            }}
          >
            <div
              style={{
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16,
                marginBottom: 100,
                marginTop: 100,
                display: "flex",
                width: "80%"
              }}
              className="crisisTable"
            >
              <table
                id="stats_table"
                style={{
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto",
                  width: "100%"
                }}
              >
                <tr
                  style={{
                    height: "40%",
                    borderBottom: "2px solid #ddd",
                    paddingBottom: 8
                  }}
                >
                  <td style={{ padding: "0 0 8px 0", color: "#410DAA" }}></td>
                  <td
                    style={{
                      padding: "0 0 8px 0",
                      color: "#bbb",
                      paddingRight: 50
                    }}
                  >
                    Jamā Wealth
                  </td>
                  <td
                    style={{
                      padding: "0 0 8px 0",
                      color: "#bbb",
                      paddingRight: 50
                    }}
                  >
                    NIFTY
                  </td>
                </tr>

                <tr>
                  <td className="paddingZero" style={{ paddingRight: "20%" }}>
                    Invested Amount
                  </td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_1yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.total_invesment}
                  </td>
                  <td className="paddingZero" id="nifty_1yr">
                    {this.state.total_invesment}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero" style={{ paddingRight: "20%" }}>
                    Value of Investment
                  </td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_3yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_total_amount}
                  </td>
                  <td className="paddingZero" id="nifty_3yr">
                    {this.state.nifty_total_amount}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero" style={{ paddingRight: "20%" }}>
                    Absolute Gain
                  </td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_abs_growth}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_abs_growth}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero" style={{ paddingRight: "20%" }}>
                    XIRR
                  </td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_xirr}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_xirr}
                  </td>
                </tr>
              </table>
            </div>
            <div className="mobile-tools">
              <Link to="/compare" target="_blank" className="btn-tools">
                <a>Compare JWEL with Nifty</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/comparemf" target="_blank" className="btn-tools">
                <a>Compare Mutual Funds Performance with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/crisiscompare" target="_blank" className="btn-tools">
                <a>Compare Previous Bear Market with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
              class=" research-invest-btn-section "
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important"
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                  style={{ backgroundColor: "#410DAA", color: "#fff" }}
                >
                  Request Callback
                </a>
              </Link>
            </div>
            {/* UNCOMMENT */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex"
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>
        </div>
        <FooterNew />
      </div>
    );
  }
}

export default HowjamaWorks;
