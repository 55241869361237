import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import "../css/style.css";
import InvestPhilosphy from "./InvestPhilosphy";
import Footer from "./Footer";
import HomepageBodyContent from "./HomepageBodyContent";
import Moneybag from "../assets/Money Bag.svg";
import magicwand from "../assets/magic-wand.svg";
import allocate from "../assets/allocate.svg";
import aboutimg from "../assets/aboutimg.svg";
import bars from "../assets/bars.svg";
import philosophyblk from "../assets/philosophyblk.png";
import howjamaimgyllo from "../assets/howjamaimgyllo.png";
import startimgyllo from "../assets/startimgyllo.png";
import Introsection from "./Introsection";
import WOW from "wowjs";
import { Helmet } from "react-helmet";
import brochure_pdf from "../assets/pdf/brochure.pdf";
import audit_report_pdf from "../assets/pdf/audit_report.pdf";
import { BrowserView, MobileView } from "react-device-detect";

import FeatureSection from "./featureSection";
const { innerWidth: width, innerHeight: height } = window;

class SebiAlert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={Object.assign(
          {},
          {
            position: "absolute",
            top: 80,
            right: 15,
            zIndex: 100,
            backgroundColor: "black",
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
            ...this.props.style
          }
        )}
      >
        <span style={{ paddingVertical: 10, width: "90%", color: "#fff" }}>
          Simply Grow Technologies Private Limited which operates the technology
          platform has applied for registration with SEBI as a Registered
          Investment Advisor, as a corporatee.
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <span style={{ cursor: "pointer" }}>
            <i
              class="material-icons"
              style={{ color: "#fff" }}
              onClick={() => {
                this.props.onClose && this.props.onClose();
              }}
            >
              close
            </i>
          </span>
        </div>
      </div>
    );
  }
}
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadVisible: false
    };
  }

  componentDidMount() {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  atBottom = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      return true;
    } else {
      return false;
    }
  };

  onScroll = () => {
    if (window.pageYOffset > window.innerHeight - 500 && !this.atBottom()) {
      this.setState({ downloadVisible: true });
    } else {
      this.setState({ downloadVisible: false });
    }
  };

  changeRoute = () => {
    this.context.router.push("/promospace/detail");
  };
  render() {
    return (
      <div>
        <Header />
        <Helmet>
          <title>
            Jama Wealth - Ethical Investment Managers | Preserve Wealth &
            Prosper More, with Machine Learning Powered Algorithms
          </title>
          <meta
            name="description"
            content="Jama Wealth Management offer a clinical equity portfolio which coupled with both machine learning and human intelligence. Get best returns in stock market with Jama Wealth equity portfolio"
          />
        </Helmet>
        {/* <div
          id="container"
          style={{ width: 500, height: 500, margin: "0 auto" }}
        ></div> */}

        <Introsection />
        <FeatureSection />
        {/* {this.state.modalVisible && (
          <>
            <BrowserView>
              <SebiAlert
                onClose={() => {
                  this.setState({ modalVisible: false });
                }}
                style={{ width: "40%" }}
              />
            </BrowserView>
            <MobileView>
              <SebiAlert
                onClose={() => {
                  this.setState({ modalVisible: false });
                }}
                style={{ width: "95%", left: 10, right: 10 }}
              />
            </MobileView>
          </>
        )} */}

        <div class="Home-section-banner-img">
          <div class="container home-tabed-section">
            <div class="row center-align main-tab-section-title">
              <h4>
                Industry Knowledge Coupled With A Robust Investment Philosophy
              </h4>
            </div>
            <div class="row">
              <div class="col s3 m4 xl3 tab-card-margin-style mobile-ver-style">
                {" "}
                <div class="left-menu-wealth-tab">
                  <div class="Jama-tab-card-btn Build-wealth-tab-btn valign-wrapper">
                    <span>
                      <span>
                        <img
                          class="mobile-show-img"
                          src={bars}
                          alt="preserve & prosper"
                        />
                      </span>
                      <span>
                        <h6 class="under-line-style" style={{ marginLeft: 0 }}>
                          Preserve & Prosper
                        </h6>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="left-menu-sub-tabs">
                  <Link to="/IndiasEconomy">
                    {" "}
                    <div class="Jama-tab-card-btn left-card-tab valign-wrapper left-menu-sub-tabs-below-wealth b-rad-t">
                      <span>
                        <img class="money-bar-img-style" src={Moneybag} />
                        <span>
                          <h6 class="under-line-style">$5 Trillion Economy</h6>
                        </span>
                      </span>
                    </div>
                  </Link>
                  <Link to="/HowtoAlocate">
                    {" "}
                    <div class="Jama-tab-card-btn left-card-tab valign-wrapper left-menu-sub-tabs-below-wealth b-t-1">
                      <span>
                        <img class="money-bar-img-style" src={allocate} />
                        <span>
                          <h6 class="under-line-style">
                            How to allocate assets
                          </h6>
                        </span>
                      </span>
                    </div>
                  </Link>
                  <Link to="/SheerMagic">
                    {" "}
                    <div class="Jama-tab-card-btn left-card-tab valign-wrapper left-menu-sub-tabs-below-wealth b-t-1 b-rad-b">
                      <span>
                        <img class="money-bar-img-style" src={magicwand} />
                        <span>
                          <h6 class="under-line-style">
                            The magic of compounding
                          </h6>
                        </span>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="col s9 m8 xl9 mobile-ver-style">
                <div class="home-right-content-section">
                  <div class="tabs-right-top-sections">
                    <div class="row m-0">
                      <div class="col m4 s4 no-padding tab-card-margin-style">
                        <div class="Jama-tab-card-btn valign-wrapper jama-right-tabs jama-right-tabs-active bg-white m-r-4 b-left">
                          <span>
                            <img
                              class="mobile-show-img"
                              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/Jamawealth/philosophyblk.png"
                              alt="Philosophy_jama_wealth"
                            />
                          </span>
                          <h6 class="under-line-style">
                            Investment Philosophy
                          </h6>
                        </div>
                      </div>
                      <div class="col m4 s4 no-padding tab-card-margin-style">
                        <Link to="/HowjamaWorks">
                          {" "}
                          <div class="Jama-tab-card-btn valign-wrapper jama-right-tabs bg-white m-r-4">
                            <span>
                              <img
                                class="mobile-show-img"
                                src={howjamaimgyllo}
                              />
                            </span>
                            <h6 class="under-line-style">How it works</h6>
                          </div>
                        </Link>
                      </div>
                      <div class="col m4 s4 no-padding tab-card-margin-style">
                        <Link to="/contactus">
                          <a target="_blank">
                            {" "}
                            <div class="Jama-tab-card-btn valign-wrapper jama-right-tabs bg-white b-right">
                              <span>
                                <img
                                  class="mobile-show-img"
                                  src="https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/Jamawealth/startimgblk.png"
                                  alt="jamawealth"
                                />
                              </span>
                              <h6 class="under-line-style">
                                Get Started{" "}
                                <span>
                                  <i class="material-icons start-arrows">
                                    arrow_right_alt
                                  </i>{" "}
                                </span>
                              </h6>
                            </div>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div class="Jama-home-tab-content-box valign-wrapper">
                    <div class="row m-0 valign-wrapper">
                      <div class="col s12 m12 l8">
                        <h5 class="mobile-title-text">Investment Philosophy</h5>
                        <p>
                          We follow a simple Investment Philosophy for JARS
                          called Roots & Wings. Roots aim to preserve wealth by
                          selecting companies with low debt, consistent
                          efficiency & promoter integrity. Wings aim to increase
                          prosperity by identifying growing companies (sales /
                          profit / cashflows)
                        </p>
                        <div class="read-more-btn">
                          <Link to="/Investphilosphyinfo">
                            <button class="read-more-btn-style">
                              Read More
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div class="col s12 m12 l4 hide-on-med-and-down">
                        <div class="tab-content-img">
                          <img class="responsive-img" src={aboutimg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* UNCOMMENT */}
        {this.state.downloadVisible && (
          <>
            <div class="fab-container">
              <div class="fab fab-icon-holder">
                <i className="material-icons">cloud_download</i>
              </div>

              <ul class="fab-options">
                <li>
                  <span class="fab-label">Latest Brochure</span>
                  <div class="fab-icon-holder">
                    <Link
                      to={brochure_pdf}
                      target="_blank"
                      download="brochure.pdf"
                      download
                    >
                      <i class="material-icons">insert_drive_file</i>
                    </Link>
                  </div>
                </li>
                <li>
                  <span class="fab-label">Audit Report</span>
                  <div class="fab-icon-holder">
                    <Link
                      to={audit_report_pdf}
                      target="_blank"
                      download="brochure.pdf"
                      download
                    >
                      <i class="material-icons">insert_drive_file</i>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            {/*<div
            style={{
              position: "fixed",
              bottom: 30,
              right: 10,
              backgroundColor: "#fff",
              border: "1.5px solid #1553a5",
              borderRadius: "6px",
              overflow: "hidden",
              cursor: "pointer",
              zIndex: 10
            }}
          >
            <Link to={pdf} target="_blank" download="brochure.pdf" download>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: 50,
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <CloudDownloadIcon
                    style={{ color: "#1553a5", fontSize: 24 }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: 20
                  }}
                >
                  <span
                    style={{ fontSize: 15, color: "#121212", paddingRight: 10 }}
                  >
                    Latest
                  </span>
                  <span style={{ fontSize: 15, color: "#121212" }}>
                    Brochure
                  </span>
                </div>
              </div>
            </Link>
          </div> */}
          </>
        )}
        <HomepageBodyContent />

        <Footer />
      </div>
    );
  }
}

export default Homepage;
