import React, { Component } from "react";
import "../css/style.css";
import "../css/how-jama-works.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Moneybag from "../assets/Money Bag.svg";
import magicwand from "../assets/magic-wand.svg";
import allocate from "../assets/allocate.svg";
import Howjamaimg from "../assets/Howjamaimg.svg";
import bars from "../assets/bars.svg";
import Header from "./Header";
import jamabrandlogo from "../assets/Jama_wealth_logo.svg";

// import HomepageBodyContent from "./HomepageBodyContent";
import Footer from "./Footer";
import philosophyyllo from "../assets/philosophyyllo.png";
import howjamaimgblk from "../assets/howjamaimgblk.png";
import startimgyllo from "../assets/startimgyllo.png";
// import WOW from "wowjs";
import Introsection from "./Introsection";
import FeatureSection from "./featureSection";
import { Helmet } from "react-helmet";
import HighStock from "highcharts/highstock";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

var to_date;
var from_date;
var nifty_cagr;
var jars_cagr;
var nifty_windows;
var jars_windows;

const options = {
  title: {
    text: "My stock chart"
  },
  series: [
    {
      data: [1, 2, 3]
    }
  ]
};

const crisis = [
  {
    name: "2008 Global Financial Crisis",
    path: "gfc"
  },

  {
    name: "European Crisis",
    path: "europe"
  },
  {
    name: "NBFC Crisis",
    path: "nbfc"
  },
  {
    name: "Post China Yuan Evaluation",
    path: "yuan"
  },
  {
    name: "Taper Tantrum",
    path: "taper"
  }
];

const links = {
  "2008 Global Financial Crisis": "gfc",
  "European Crisis": "europe",
  "Taper Tantrum": "taper",
  "Post China Yuan Evaluation": "yuan",
  "NBFC Crisis": "nbfc"
};

class HowjamaWorks extends Component {
  state = {
    data: null,
    from: null,
    to: null,
    display_from: null,
    selectedCrisis: null,
    showDropDown: false
  };
  shouldComponentUpdate(nextProps, nextState) {
    return this.state.redraw != nextState.redraw;
  }

  DispMFbyName = async name => {
    var amc_id = "";
    // console.log("Name is: ", name);
    for (var temp = 0; temp < this.state.amc.data.results.length - 1; temp++) {
      if (this.state.amc.data.results[temp]["name"] === name) {
        // console.log("ID is: ", this.state.amc.data.results[temp]["amcfund_id"]);
        amc_id = this.state.amc.data.results[temp]["amcfund_id"];
        // break;
        // return this.state.amc.data.results[temp]["amcfund_id"];
      }
      // return;
    }
    if (amc_id === "") {
      return;
    }
    if (this.state.data.length > 7) {
      alert("Only 5 Mutual Funds allowed");
      return;
    }
    var MFData = await axios.post(
      "https://docker.jama.co.in/app/api/get_fund_nav_logs/",
      { amcfund_id: amc_id }
    );
    // console.log("type of amc_id is:", typeof amc_id);
    // console.log("MF DATA IS:", MFData);
    var navs = [];
    for (var temp = 0; temp < MFData.data.results.length - 1; temp++) {
      var datemf = new Date(MFData.data.results[temp]["as_of_date"]);
      navs.push([datemf.getTime(), MFData.data.results[temp]["latest_nav"]]);
    }
    // console.log(navs);
    const data = {
      name: name,
      data: navs
    };
    this.chart.xAxis[0].setExtremes(navs[0][0], navs[navs.length - 1][0]);
    this.setState({
      data: [...this.state.data, data]
    });
    return 0;
  };

  resetSeries = async () => {
    var serieslen = this.chart.series.length - 2;
    this.setState(
      {
        data: this.state.data.slice(0, 2)
      },
      () => {
        // console.log("ELEMENT POPPED")
      }
    );
    this.chart.xAxis[0].setExtremes(
      this.state.data[0].data[0][0],
      this.state.data[0].data[this.state.data[0].data.length - 1][0]
    );
  };
  DispPathbyName = async name => {
    if (name && Object.keys(links).includes(name))
      window.open(`/crisisCompare/?crisis=${links[name]}`, "_blank");
    // this.props.history.push(`/crisisCompare/?crisis=${links[name]}`);

    // switch (name) {
    //   case "2008 Global Financial Crisis":
    //     this.props.history.push(`/crisisCompare/?crisis=${links[name]}`);
    //     break;
    //   case "European Crisis":
    //     break;
    //   case "Taper Tantrum":
    //     break;
    //   case "Post China Yuan Evaluation":
    //     break;
    //   case "NBFC Crisis":
    //     break;

    //   default:
    //     break;
    // }
    // for (var na = 0; na < crisis.length; na++) {
    //   if (name === crisis[na]["name"]) {
    //     return "" + crisis[na]["path"];
    //   }
    // }
  };
  printExtremes = () => {
    // console.log("Redraw is: " + this.state.redraw);
    try {
      var new_from = new Date(this.chart.xAxis[0].getExtremes().min);
      var new_to = new Date(this.chart.xAxis[0].getExtremes().max);
      if (
        (this.state.from.getTime() + " ").localeCompare(
          this.chart.xAxis[0].getExtremes().min + " "
        ) != 0
      ) {
        this.setState({ from: new_from, to: new_to }, () => {
          this.selectDay(new_from, true);
          // console.log("from is: " + this.state.from.getTime());
          // console.log("other is: " + this.chart.xAxis[0].getExtremes().min);
          this.update_stats(new_to.getTime(), new_from.getTime());
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  indexOfmin(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var min = arr[0];
    var minIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] < min) {
        minIndex = i;
        min = arr[i];
      }
    }
    return [minIndex, min];
  }
  indexOfmax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return [maxIndex, max];
  }
  get_only_navs(data) {
    var onlynav = [];
    for (var i = 0; i < data.length - 1; i++) {
      onlynav.push(data[i][1]);
    }
    return onlynav;
  }
  getMean(data) {
    return (
      data.reduce(function(a, b) {
        return Number(a) + Number(b);
      }) / data.length
    );
  }
  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }
  // getSD(data) {
  //   let m = this.getMean(data);
  //   return (
  //     Math.round(
  //       Math.sqrt(
  //         data.reduce(function(sq, n) {
  //           return sq + Math.pow(n - m, 2);
  //         }, 0) /
  //           (data.length - 1)
  //       ) * 1000
  //     ) / 1000
  //   );
  // }

  getSD(data) {
    // console.log("STANDARD DEVIATION DATA IS", data)
    return Math.std(data)
  }
  get_yearly_sd(data, year, to) {
    var from = to - year * (1000 * 60 * 60 * 24 * 365);
    var todate = new Date(to);
    var fromdate = new Date(from);
    var today = todate.getDay();
    if (today == 0) {
      to = to - 2 * (1000 * 60 * 60 * 24);
    } else if (today == 6) {
      to = to - 1000 * 60 * 60 * 24;
    }
    var fromday = fromdate.getDay();
    if (fromday == 0) {
      from = from - 2 * (1000 * 60 * 60 * 24);
    } else if (fromday == 6) {
      from = from - 1000 * 60 * 60 * 24;
    }
    var start_index = 0;
    var end_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= from) {
        start_index = j;
        break;
      }
    }

    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= to) {
        end_index = data.length - 1 - j;
        break;
      }
    }
    return this.getSD(
      this.get_only_navs(data.slice(start_index, end_index + 1))
    );
  }
  get_window(to, from, data) {
    var start = from;
    var end = to;
    var startday = new Date(from);
    var endday = new Date(to);
    startday = startday.getDay();
    endday = endday.getDay();
    // console.log("cagrs_dates_window", startday, endday);
    var start_index = 0;
    var end_index = data.length - 1;
    if ((startday == 0) | (startday == 6)) {
      start = start + 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 0) {
      end = end - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      end = end - 1 * (1000 * 60 * 60 * 24);
    }
    var from_index = 0;
    var to_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= end) {
        to_index = data.length - 1 - j;
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start) {
        from_index = j;
        break;
      }
    }
    return data.slice(from_index, to_index + 1);
  }
  get_cagr(data) {
    // console.log("CAGR START DATE IS: " + data[0][1]);
    // console.log("CAGR START DATE IS end: " + data[data.length - 1][1]);

    var days = (data[data.length - 1][0] - data[0][0]) / (1000 * 60 * 60 * 24);
    // console.log("CAGR days are: " + days);
    var jars_cagr =
      Math.pow(data[data.length - 1][1] / data[0][1], 365 / days) - 1;

    // console.log((data[data.length-1][1]/data[0][1]));
    // console.log((1/(days/365)));
    // console.log("JARS CAGR: " + jars_cagr * 100);
    // nifty_cagr = Math.pow(((niftynav[niftynav.length - 1] - niftynav[0])/niftynav[0]) * 100, (1/(days/365)));
    // console.log(nifty_cagr);
    return Math.round(jars_cagr * 100 * 100) / 100 + "%";
  }

  get_year_returns_from_to(data, fromdate, date) {
    // var start = date - years * 1000 * 60 * 60 * 24 * 365;
    const no_of_years = (date - fromdate) / (1000 * 60 * 60 * 24 * 365);
    // console.log("no_of_years", no_of_years.toFixed(2));
    // console.log("date", fromdate, date);
    // var start = fromdate;
    // // console.log(data[data.length - 1]);
    // var startday = new Date(start);
    // var endday = new Date(date);
    // // console.log("LOOP DATE is: " + startday);
    // startday = startday.getDay();
    // endday = endday.getDay();
    var start_index = 0;
    var end_index = data.length - 1;
    // if ((startday == 0) | (startday == 6)) {
    //   start = start + 2 * (1000 * 60 * 60 * 24);
    // } else if (endday == 0) {
    //   date = date - 2 * (1000 * 60 * 60 * 24);
    // } else if (endday == 6) {
    //   date = date - 1 * (1000 * 60 * 60 * 24);
    // }

    for (var j = 0; j < data.length; j++) {
      if (data[j][0] > fromdate) {
        start_index = j;
        // console.log("START INDEX IS: " + data[j][0]);
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= date) {
        end_index = data.length - 1 - j;
        // console.log("END INDEX IS: " + data[data.length - 1 - j][1]);
        break;
      }
    }
    // console.log("Future Value: " + data[end_index][1]);
    // console.log("Present Value: " + data[start_index][1]);
    const start_nav = data[start_index][1];
    const end_nav = data[end_index][1];
    const annaualised = Math.pow(end_nav / start_nav, 1 / no_of_years) - 1;
    // console.log("annaualised", annaualised);
    var returns =
      (data[end_index][1] - data[start_index][1]) / data[start_index][1];
    // console.log(years + " Years returns is: " + returns * 100);
    if (no_of_years >= 1) {
      return (Math.round(annaualised * 100 * 100) / 100).toFixed(1) + "% p.a";
    } else {
      return (
        (Math.round(((end_nav / start_nav) - 1) * 100 * 100) / 100).toFixed(1) +
        "% abs"
      );
    }
  }

  get_year_returns(data, years, date) {
    var start = date - years * 1000 * 60 * 60 * 24 * 365;
    // console.log(data[data.length - 1]);
    var startday = new Date(start);
    var endday = new Date(date);
    // console.log("LOOP DATE is: " + startday);
    startday = startday.getDay();
    endday = endday.getDay();
    var start_index = 0;
    var end_index = data.length - 1;
    if ((startday == 0) | (startday == 6)) {
      start = start + 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 0) {
      date = date - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      date = date - 1 * (1000 * 60 * 60 * 24);
    }
    // console.log("Return date is: " + date + " and " + start);
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start) {
        start_index = j;
        break;
        // console.log("START INDEX IS: " + start_index);
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= date) {
        end_index = data.length - 1 - j;
        break;
        // console.log("END INDEX IS: " + j);
      }
    }
    // console.log("Future Value: " + data[end_index][1]);
    // console.log("Present Value: " + data[start_index][1]);
    const start_nav = data[start_index][1];
    const end_nav = data[end_index][1];
    const annaualised = Math.pow(end_nav / start_nav, 1 / years) - 1;

    var returns =
      (data[end_index][1] - data[start_index][1]) / data[start_index][1];
    // console.log(years + " Years returns is: " + returns * 100);
    return (Math.round(annaualised * 100 * 100) / 100).toFixed(1) + "%";
  }
  get_year_cagr(data, years, from, to) {
    if (to - from >= years * 1000 * 60 * 60 * 24 * 365) {
      var start = to - years * 1000 * 60 * 60 * 24 * 365;
      var yearcagr = this.get_cagr(this.get_window(to, start, data));
      return yearcagr;
    } else {
      return "-";
    }
  }
  init_stats(todate, fromdate) {
    window.stats = {
      redraw: 0,
      nifty_cagr: 0,
      jars_cagr: 0,
      jars_1yr_returns: 0,
      jars_3yr_returns: 0,
      jars_5yr_returns: 0,
      jars_10yr_returns: 0,
      nifty_1yr_returns: 0,
      nifty_3yr_returns: 0,
      nifty_5yr_returns: 0,
      nifty_10yr_returns: 0,
      jars1yr_sd: 0,
      jars3yr_sd: 0,
      jars5yr_sd: 0,
      jars10yr_sd: 0,
      nifty1yr_sd: 0,
      nifty3yr_sd: 0,
      nifty5yr_sd: 0,
      nifty10yr_sd: 0
    };
  }
  update_stats_new(todate, fromdate) {
    window.stats = {
      redraw: 0,
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_from_to: this.get_year_returns_from_to(
        this.get_window(todate, fromdate, this.state.data[1].data),
        fromdate,
        todate
      ),
      nifty_from_to: this.get_year_returns_from_to(
        this.get_window(todate, fromdate, this.state.data[0].data),
        fromdate,
        todate
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
      jars_1yr_returns: this.get_year_returns(
        this.state.data[1].data,
        1,
        todate
      ),
      jars_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        3,
        todate
      ),
      jars_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        5,
        todate
      ),
      jars_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        10,
        todate
      ),
      nifty_1yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        1,
        todate
      ),
      nifty_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        3,
        todate
      ),
      nifty_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        5,
        todate
      ),
      nifty_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        10,
        todate
      ),
      jars1yr_sd: this.get_yearly_sd(this.state.data[1].data, 1, todate),
      jars3yr_sd: this.get_yearly_sd(this.state.data[1].data, 3, todate),
      jars5yr_sd: this.get_yearly_sd(this.state.data[1].data, 5, todate),
      jars10yr_sd: this.get_yearly_sd(this.state.data[1].data, 10, todate),
      nifty1yr_sd: this.get_yearly_sd(this.state.data[0].data, 1, todate),
      nifty3yr_sd: this.get_yearly_sd(this.state.data[0].data, 3, todate),
      nifty5yr_sd: this.get_yearly_sd(this.state.data[0].data, 5, todate),
      nifty10yr_sd: this.get_yearly_sd(this.state.data[0].data, 10, todate)
    };
    this.forceUpdate();
  }
  update_stats(todate, fromdate) {
    // this.setState({redraw : 0},() => console.log("Redraw Changed"));
    // console.log("update_stats", todate, fromdate);
    this.setState(
      {
        nifty_cagr: this.get_cagr(
          this.get_window(todate, fromdate, this.state.data[0].data)
        ),
        jars_cagr: this.get_cagr(
          this.get_window(todate, fromdate, this.state.data[1].data)
        ),
        jars_from_to: this.get_year_returns_from_to(
          this.state.data[1].data,
          fromdate,
          todate
        ),
        nifty_from_to: this.get_year_returns_from_to(
          this.state.data[0].data,
          fromdate,
          todate
        ),
        jars_1yr_returns: this.get_year_cagr(
          this.state.data[1].data,
          1,
          fromdate,
          todate
        ),
        jars_3yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[1].data),
          3,
          fromdate,
          todate
        ),
        jars_5yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[1].data),
          5,
          fromdate,
          todate
        ),
        jars_10yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[1].data),
          10,
          fromdate,
          todate
        ),
        nifty_1yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[0].data),
          1,
          fromdate,
          todate
        ),
        nifty_3yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[0].data),
          3,
          fromdate,
          todate
        ),
        nifty_5yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[0].data),
          5,
          fromdate,
          todate
        ),
        nifty_10yr_returns: this.get_year_cagr(
          this.get_window(todate, fromdate, this.state.data[0].data),
          10,
          fromdate,
          todate
        ),
        jars1yr_sd: this.get_yearly_sd(this.state.data[1].data, 1, todate),
        jars3yr_sd: this.get_yearly_sd(this.state.data[1].data, 3, todate),
        jars5yr_sd: this.get_yearly_sd(this.state.data[1].data, 5, todate),
        jars10yr_sd: this.get_yearly_sd(this.state.data[1].data, 10, todate),
        nifty1yr_sd: this.get_yearly_sd(this.state.data[0].data, 1, todate),
        nifty3yr_sd: this.get_yearly_sd(this.state.data[0].data, 3, todate),
        nifty5yr_sd: this.get_yearly_sd(this.state.data[0].data, 5, todate),
        nifty10yr_sd: this.get_yearly_sd(this.state.data[0].data, 10, todate),
        redraw: this.state.redraw + 1
      }
      // () => {console.log("update_stats_state", this.state)}
    );
  }

  handleFromChange = day => {
    if (Object.prototype.toString.call(day) != "[object Date]") {
      return 0;
    }
    if (day >= this.state.to) {
      alert("From Date must be lesser than To Date");
      return 0;
    }
    var f_date = new Date(day);
    f_date.setDate(day.getDate() - 2);
    this.setState({ from: f_date, display_from: day }, () => {
      this.selectDay(f_date, true);
      this.calculateCagrs(this.state.to, this.state.from - 23400000);
      if (this.state.to && this.state.from) {
        this.update_stats(this.state.to, this.state.from - 23400000);
      }
    });
  };

  handleToChange = day => {
    if (day <= this.state.from) {
      alert("To Date must be greater than From Date");
      return 0;
    }
    this.setState({ to: day, display_to: day }, () => {
      this.selectDay(day);
      this.calculateCagrs(this.state.to - 23400000, this.state.from - 23400000);
      this.update_stats(this.state.to - 23400000, this.state.from - 23400000);
    });
    // this.chart.xAxis[0].setExtremes(this.state.data[0].data[400][0],this.state.data[0].data[800][0]);
  };
  selectDay = (time, isMin) => {
    let timestamp;
    if (time) {
      timestamp = time.getTime();
    } else {
      if (isMin) {
        timestamp = this.state.from;
      } else {
        timestamp = this.state.to;
      }
    }
    const xAxis = this.chart.xAxis[0];

    xAxis.setExtremes(
      isMin ? timestamp : xAxis.min,
      isMin ? xAxis.max : timestamp
    );
    this.setState(isMin ? { minInput: false } : { maxInput: false });
  };

  onChange = date => this.setState({ date });
  async componentDidMount() {
    // new WOW.WOW().init();
    // window.scrollTo(0, 0);

    const jars = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/jars_nav_values.json"
    );

    const nifty = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty_nav_values.json"
    );
    const nifty50TRI = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty50tri_nav_values.json"
    );
    const nifty50 = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty50_nav_values.json"
    );
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    const amcfunds = await axios.get(
      "https://docker.jama.co.in/app/api/get_equity_amcfunds/",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    );
    var amcfundnames = [];
    var dropdown = document.getElementById("crisisdatalist");
    // console.log(amcfunds.data.results);
    // for (var funds = 0; funds < crisis.length; funds++) {
    //   var el = document.createElement("option");
    //   el.id = crisis[funds]["path"];
    //   el.value = crisis[funds]["name"];
    //   dropdown.appendChild(el);
    // }
    const getDates = nifty.data;

    this.setState({
      data: [
        // {
        //   name: "Nifty",
        //   color: "red",
        //   data: nifty.data,
        //   // lineColor: "transparent",
        //   visible: true,
        // },
        {
          name: "Nifty 50",
          data: nifty50TRI.data,
          color: "red"
        },
        {
          name: "Jamā Wealth",
          data: jars.data,
          color: "#410Daa",
          id: "JAMA"
        },
        // {
        //   name: "Nifty 50",
        //   data: nifty50.data,
        //   // color: 'transparent',
        //   lineColor: "transparent",
        // },
        // {
        //   name: "Jamā Deployment",
        //   type: "flags",
        //   data: [
        //     {
        //       x: Date.UTC(2019, 8, 22),
        //       title: "JARS Deployment",
        //       text: "From today JARS was deployed in the market",
        //     },
        //   ],
        //   onSeries: "JAMA",
        //   shape: "sqaurepin",
        //   width: 50,
        //   height: 20,
        // },
        {
          name: "Eurpean Crisis",
          type: "flags",
          data: [
            {
              x: Date.UTC(2011, 12, 20),
              title: "JARS Deployment",
              text: "From today JARS was deployed in the market"
            }
          ],
          onSeries: "JAMA",
          shape: "sqaurepin",
          width: 50,
          height: 20,
          visible: false
        }
      ],
      amc: amcfunds,
      from: new Date(jars.data[0][0]),
      display_from: new Date(jars.data[0][0]),
      to: new Date(getDates.pop()[0]),
      display_to: new Date(nifty.data[0][0]),
      nifty_cagr: this.get_cagr(nifty.data),
      jars_cagr: this.get_cagr(jars.data),
      redraw: 1

      // from: moment(nifty.data[0][0]),
      // to: moment(getDates.pop()[0])
    });
    if (this.state.to && this.state.from) {
      this.update_stats(this.state.to.getTime(), this.state.from.getTime());
    }
    // this.update_stats_new(this.state.to.getTime(), this.state.from.getTime());
  }

  calculateCagrs = (todate, fromdate) => {
    this.setState({
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      )
    });
  };

  buttonClick = value => {
    let newDate;
    if (value == -1) {
      newDate = new Date(new Date().getFullYear(), 0, 1);
    } else {
      newDate = moment().subtract(value, "months");
    }

    // const nifty_cagr = this.get_cagr(
    //   this.get_window(
    //     this.state.to.getTime(),
    //     this.state.from - 23400000,
    //     this.state.data[0].data
    //   )
    // );

    // const jars_cagr = this.get_cagr(
    //   this.get_window(
    //     this.state.to.getTime(),
    //     this.state.from - 23400000,
    //     this.state.data[1].data
    //   )
    // );

    // console.log("cagrs", jars_cagr, nifty_cagr);
    // console.log("cagrs_date", this.state.to, this.state.from);
    // console.log(
    //   "cagrs_date_formatted",
    //   this.state.to.getTime(),
    //   this.state.from.getTime()
    // );
    this.setState(
      {
        from: new Date(newDate),
        display_from: new Date(newDate)
      },
      () => {
        this.calculateCagrs(this.state.to, this.state.from);
        // this.update_stats(this.state.to.getTime(), this.state.from.getTime());
      }
    );
  };

  render() {
    var redraw1 = 1;
    var yearly_dict = [9, 5, 9, 6, 9, 7, 9, 8, 9, 9, 9];
    var monthly_dict = [9, 0, 9, 1, 9, 9, 2, 9, 9, 3, 9, 9, 5];
    var range_dict = yearly_dict;
    var yearly_range = this.getUrlVars()["years"];
    var default_range = yearly_range;
    var month_range = this.getUrlVars()["months"];
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    if (month_range > 0) {
      range_dict = monthly_dict;
      default_range = month_range;
    }
    const { from, to, selectedCrisis, display_from, display_to } = this.state;
    this.init_stats(this.state.to, this.state.from);
    const compareOptions = {
      credits: {
        enabled: false
      },
      chart: {
        renderTo: "container",
        height: 400,
        zIndex: -1,
        events: {
          redraw: () => {
            this.printExtremes();
          }
        }
      },
      exporting: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        selected: range_dict[default_range],
        enabled: true,
        inputEnabled: false,
        verticalAlign: "bottom",
        buttonTheme: {
          // styles for the buttons
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 8,
          style: {
            color: "#039",
            fontWeight: "bold"
          },
          states: {
            hover: {},
            select: {
              fill: "#039",
              style: {
                color: "white"
              }
            }
            // disabled: { ... }
          }
        },
        inputBoxBorderColor: "gray",
        inputBoxWidth: 120,
        inputBoxHeight: 18,
        inputStyle: {
          color: "#039",
          fontWeight: "bold"
        },
        labelStyle: {
          color: "silver",
          fontWeight: "bold"
        },
        buttons: [
          {
            type: "month",
            count: 1,
            text: "1m",
            events: {
              click: () => this.buttonClick(1)
            }
          },
          {
            type: "month",
            count: 3,
            text: "3m",
            events: {
              click: () => this.buttonClick(3)
            }
          },
          {
            type: "month",
            count: 6,
            text: "6m",
            events: {
              click: () => this.buttonClick(6)
            }
          },
          {
            type: "month",
            count: 9,
            text: "9m",
            events: {
              click: () => this.buttonClick(9)
            }
          },
          {
            type: "ytd",
            text: "YTD",
            events: {
              click: () => this.buttonClick(-1)
            }
          },
          {
            type: "year",
            count: 1,
            text: "1y",
            events: {
              click: () => this.buttonClick(12)
            }
          },
          {
            type: "year",
            count: 3,
            text: "3y",
            events: {
              click: () => this.buttonClick(36)
            }
          },
          {
            type: "year",
            count: 5,
            text: "5y",
            events: {
              click: () => this.buttonClick(60)
            }
          },
          {
            type: "year",
            count: 7,
            text: "7y",
            events: {
              click: () => this.buttonClick(84)
            }
          },
          {
            type: "year",
            count: 10,
            text: "10y",
            events: {
              click: () => this.buttonClick(120)
            }
          },
          {
            type: "all",
            text: "All",
            events: {
              click: () => this.buttonClick(0)
            }
          }
        ]
      },
      yAxis: {
        labels: {
          align: "left",
          formatter: function() {
            return (this.value > 0 ? " + " : "") + this.value + "%";
          }
        },
        plotLines: [
          {
            value: 0,
            width: 2,
            color: "silver"
          }
        ]
      },
      xAxis: {
        events: {
          setExtremes: function(e) {}
        }
      },
      plotOptions: {
        series: {
          compare: "percent",
          showInNavigator: true,
          compareStart: true
        },
        flags: {
          height: 30
        }
      },
      lang: {
        thousandsSep: ","
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color};font-weight:700;">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
        valueDecimals: 2,
        split: true
      },
      series: this.state.data
    };

    return (
      <div>
        <Header />
        <Helmet>
          <title>
            {" "}
            Compare Jama Wealth Portfolio Perofrmance With Nifty 50 | Jama
            Wealth{" "}
          </title>
          <meta
            name="description"
            content="Equity investment portfolios, selected by experts and machine learning algorithms.
For busy professionals, CXOs and business-owners."
          ></meta>
        </Helmet>
        <script>this.update_stats_new(this.state.to,this.state.from)</script>
        <div
          class="jars-section-title-heading"
          style={{ margin: "80px auto 0", textAlign: "center" }}
        >
          <h5>Compare Jamā Wealth Equity with Nifty 50</h5>
        </div>

        {/*{crisis.map((crisisItem, index) => (
            <Link
              to={{
                pathname: `/crisisCompare/?crisis=${crisisItem.path}`,
                query: { thing: "asdf", another1: "stuff" },

                state: { referrer: index },
                crisisProps: {
                  selectedCrisis: index,
                },
              }}
              target="_blank"
              key={index}
            >
              <div>
                <p
                  style={{
                    color: selectedCrisis === index ? "#410daa" : "#bbb",
                  }}
                >
                  {crisisItem.name}
                </p>

                {selectedCrisis == index && (
                  <div
                    style={{
                      width: 60,
                      height: 2,
                      backgroundColor: "#410daa",
                      marginLeft: 8,
                    }}
                  ></div>
                )}
              </div>
            </Link>

          ))}
          */}

        <div className="compareTableGraph">
          <div
            style={{ width: "90%", float: "left", margin: "0 auto" }}
            className="secondaryWrapper"
          >
            {this.state.data && (
              <table
                id="stats_table"
                style={{
                  // float: "centre",
                  alignItems: "flex-end",
                  margintop: "auto",
                  padding: 50
                }}
              >
                <tr
                  style={{
                    // height: "40%",
                    paddingBottom: 8
                  }}
                >
                  <td
                    style={{
                      padding: "0 0 8px 0",
                      color: "#410DAA",
                      width: "50%"
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        margin: "0 auto",
                        maxWidth: 400,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 16
                        // float: "right"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          float: "right",
                          marginRight: 10
                        }}
                      >
                        <p
                          style={{
                            marginRight: 8,
                            color: "#bbb",
                            marginBottom: 5,
                            paddingBottom: 0
                          }}
                        >
                          From
                        </p>
                        <DayPickerInput
                          style={{
                            border: "1px solid #ddd",
                            width: 100,
                            padding: 4,
                            borderRadius: 8
                            // height: 30
                          }}
                          inputProps={{
                            style: {
                              border: "none",
                              height: "auto",
                              margin: 0,
                              textAlign: "center",
                              fontSize: 12
                            }
                          }}
                          onDayChange={this.handleFromChange}
                          value={display_from}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <p
                          style={{
                            marginRight: 8,
                            color: "#bbb",
                            marginLeft: 0,
                            marginBottom: 5,
                            paddingBottom: 0
                          }}
                        >
                          To
                        </p>
                        <DayPickerInput
                          style={{
                            border: "1px solid #ddd",
                            width: 100,
                            padding: 4,
                            borderRadius: 8
                            // height: 30
                          }}
                          inputProps={{
                            style: {
                              border: "none",
                              height: "auto",
                              margin: 0,
                              textAlign: "center",
                              fontSize: 12
                            },
                            readonly: true
                          }}
                          onDayChange={this.handleToChange}
                          value={to}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      padding: "0px 0 8px 0",
                      color: "#410DAA",
                      alignItems: "flex-start",
                      width: "25%",
                      verticalAlign: "center"
                    }}
                  >
                    <p
                      style={{
                        color: "#bbb",
                        marginBottom: 15,
                        paddingBottom: 0
                      }}
                    >
                      Jama Wealth
                    </p>
                    <p>{this.state.jars_from_to}</p>
                  </td>
                  <td
                    style={{
                      padding: "0px 0 8px 0",
                      color: "#121212",
                      alignItems: "center",
                      width: "25%",
                      verticalAlign: "center"
                    }}
                  >
                    <p
                      style={{
                        color: "#bbb",
                        marginBottom: 15,
                        paddingBottom: 0
                      }}
                    >
                      Nifty 50
                    </p>
                    <p>{this.state.nifty_from_to}</p>
                  </td>
                </tr>
              </table>
            )}
            {/*
            <div style={{padding: 16}}>

            <label>
                Choose a fund from this list:
                <input list="amcdatalist" id="inputamc" onChange={(e) => console.log(this.DispMFbyName(e.target.value))}/>
            </label>

            <datalist id="amcdatalist">

            </datalist>
            <button onClick={() => this.resetSeries()}>Reset</button>
            </div>
            */}
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 700,
                float: "right"
              }}
              className="mobileGraphWrapper"
            >
              <div
                style={{ float: "right", display: "none" }}
                className="crisisContainer"
              >
                <h7
                  style={{
                    color: "#121212",
                    fontWeight: "bold",
                    float: "right"
                  }}
                >
                  <b>Previous Bear Markets: </b>
                </h7>

                <div
                  style={{
                    width: 200,
                    border: "1px solid #410daa",
                    borderRadius: 8,
                    height: 36,
                    position: "relative"
                  }}
                  onClick={() =>
                    this.setState({
                      showDropDown: !this.state.showDropDown
                    })
                  }
                >
                  <p style={{ height: 24 }}>{this.state.selectedCrisis}</p>

                  {this.state.showDropDown && (
                    <div
                      style={{
                        zIndex: 100,
                        position: "absolute",
                        backgroundColor: "grey",
                        color: "#fff",
                        borderRadius: 8
                      }}
                    >
                      {crisis.map((crisisItem, index) => {
                        return (
                          <p
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                selectedCrisis: crisisItem.name
                              });
                              this.DispPathbyName(crisisItem.name);
                            }}
                          >
                            {crisisItem.name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                  {/* <label>
                    <input
                      list="crisisdatalist"
                      id="inputamc"
                      class="input-amc"
                      onChange={(e) => {
                        // console.log(e.target.value);
                        this.DispPathbyName(e.target.value);
                      }}
                    />
                  </label>
                  <datalist id="crisisdatalist"></datalist> */}
                </div>
              </div>
              <br></br>
              <br></br>

              {this.state.data ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 32
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16
                      }}
                    >
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "#410daa",
                          marginRight: 8
                        }}
                      ></div>
                      <p>Jamā Wealth Equity Long</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "red",
                          marginRight: 8
                        }}
                      ></div>
                      <p>Nifty</p>
                    </div>
                  </div>
                  <HighchartsReact
                    style={{ heigh: 1000, zIndex: -1 }}
                    ref={"chart"}
                    highcharts={HighStock}
                    constructorType={"stockChart"}
                    // options={compareOptions}
                    callback={chart => {
                      if (window.innerWidth <= 850) {
                        this.chart = chart;
                      }
                    }}
                    options={{
                      ...compareOptions
                    }}

                    // onClick={ this.printExtremes() }
                  />
                </>
              ) : (
                <div
                  style={{
                    width: 500,
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div class={"loader"}></div>
                </div>
              )}
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5
                }}
                class=" research-invest-btn-section "
              >
                <Link style={{ width: "85%" }} to="/contactus">
                  <a
                    style={{
                      float: "center",
                      borderRadius: "10px!important"
                    }}
                    class="waves-effect waves-light btn btn-large btn-orange m-r-15"
                    style={{ backgroundColor: "#ffc20e" }}
                  >
                    Request Callback
                  </a>
                </Link>
              </div>
              {/* UNCOMMENT */}
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p
                  style={{
                    width: "84%",
                    color: "#410daa",
                    justifyContent: "center",
                    float: "center",
                    display: "flex"
                  }}
                >
                  Get a free health check on stock portfolio from advisor.
                </p>
              </div>
            </div>
            <div
              style={{
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16
              }}
              className="crisisTable"
            >
              <table
                id="stats_table"
                style={{
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto",
                  padding: 50
                }}
              >
                <tr
                  style={{
                    height: "40%",
                    borderBottom: "2px solid #ddd",
                    paddingBottom: 8
                  }}
                >
                  <td style={{ padding: "0 0 8px 0", color: "#410DAA" }}>
                    Annualized Return
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    Jamā Wealth
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    Nifty 50
                  </td>
                </tr>

                {/* // <tr>
                  //   <td className="paddingZero">Selected Period</td>
                  //   <td
                  //     className="paddingZero"
                  //     id="jars_cagr"
                  //     style={{ color: "#410Daa" }}
                  //   >
                  //     {this.state.jars_cagr}
                  //   </td>
                  //   <td className="paddingZero" id="nifty_cagr">
                  //     {this.state.nifty_cagr}
                  //   </td>
                  // </tr> */}

                <tr>
                  <td className="paddingZero">1 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_1yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_1yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_1yr">
                    {this.state.nifty_1yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">3 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_3yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_3yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_3yr">
                    {this.state.nifty_3yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">5 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_5yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_5yr_returns}
                  </td>
                </tr>
                <tr>
                  <td className="paddingZero">10 Year</td>
                  <td
                    className="paddingZero tableContentBold"
                    id="jars_5yr"
                    style={{ color: "#410Daa" }}
                  >
                    {this.state.jars_10yr_returns}
                  </td>
                  <td className="paddingZero" id="nifty_5yr">
                    {this.state.nifty_10yr_returns}
                  </td>
                </tr>
              </table>
            </div>
            <br />

            <div>
              <Link to="/comparemf" target="_blank" class="btn-tools">
                <a>Compare Mutual Funds Performance with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/crisiscompare" target="_blank" class="btn-tools">
                <a>Compare Previous Bear Market with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/SIP" target="_blank" class="btn-tools">
                <a style={{ borderBottom: "none" }}>
                  Compare SIP Performance of JWEL vs Nifty
                </a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
              class=" research-invest-btn-section mobCTA"
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important"
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange m-r-15"
                  style={{ backgroundColor: "#ffc20e" }}
                >
                  Request Callback
                </a>
              </Link>
            </div>
            {/* UNCOMMENT */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center"
              }}
              className="mobCTA"
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex"
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
            {/* UNCOMMENT ENDS */}

            <div
              style={{
                width: "70%",
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16
              }}
            >
              <table
                id="std_table"
                style={{
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto",
                  padding: 50
                }}
              >
                <tr
                  style={{ borderBottom: "2px solid #ddd", paddingBottom: 8 }}
                >
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    Standard Deviation
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    Jamā Wealth
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>NIFTY</td>
                </tr>
                <tr>
                  <td>1 Year</td>
                  <td style={{ color: "#410DAA" }}>{this.state.jars1yr_sd}</td>
                  <td>{this.state.nifty1yr_sd}</td>
                </tr>
                <tr>
                  <td>3 Year</td>
                  <td style={{ color: "#410DAA" }}>{this.state.jars3yr_sd}</td>
                  <td>{this.state.nifty3yr_sd}</td>
                </tr>
                <tr>
                  <td>5 Year</td>
                  <td style={{ color: "#410DAA" }}>{this.state.jars5yr_sd}</td>
                  <td>{this.state.nifty5yr_sd}</td>
                </tr>
                <tr>
                  <td>10 Year</td>
                  <td style={{ color: "#410DAA" }}>{this.state.jars10yr_sd}</td>
                  <td>{this.state.nifty10yr_sd}</td>
                </tr>
              </table>
            </div>

            <div
              style={{
                margin: "0 auto",
                textAlign: "center"
              }}
            >
              <Link to="/crisiscompare/1">
                <button>Portfolio in Crisis</button>
              </Link>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              margin: "0 auto",
              maxWidth: 700,
              float: "right"
            }}
            className="graphWrapper"
          >
            <div
              style={{ float: "right", display: "none" }}
              className="crisisContainer"
            >
              <h7
                style={{ color: "#121212", fontWeight: "bold", float: "right" }}
              >
                <b>Previous Bear Markets: </b>
              </h7>

              <div
                style={{
                  width: 200,
                  border: "1px solid #410daa",
                  borderRadius: 8,
                  height: 36,
                  position: "relative"
                }}
                onClick={() =>
                  this.setState({
                    showDropDown: !this.state.showDropDown
                  })
                }
              >
                <p style={{ height: 24 }}>{this.state.selectedCrisis}</p>

                {this.state.showDropDown && (
                  <div
                    style={{
                      zIndex: 100,
                      position: "absolute",
                      backgroundColor: "grey",
                      color: "#fff",
                      borderRadius: 8
                    }}
                  >
                    {crisis.map((crisisItem, index) => {
                      return (
                        <p
                          style={{ color: "#fff", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              selectedCrisis: crisisItem.name
                            });
                            this.DispPathbyName(crisisItem.name);
                          }}
                        >
                          {crisisItem.name}
                        </p>
                      );
                    })}
                  </div>
                )}
                {/* <label>
                  <input
                    list="crisisdatalist"
                    id="inputamc"
                    class="input-amc"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      this.DispPathbyName(e.target.value);
                    }}
                  />
                </label>
                <datalist id="crisisdatalist"></datalist> */}
              </div>
            </div>
            <br></br>
            <br></br>

            {this.state.data ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 32
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 16
                    }}
                  >
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "#410daa",
                        marginRight: 8
                      }}
                    ></div>
                    <p>Jamā Wealth Equity Long</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "red",
                        marginRight: 8
                      }}
                    ></div>
                    <p>Nifty</p>
                  </div>
                </div>
                <HighchartsReact
                  style={{ heigh: 1000, zIndex: -1 }}
                  ref={"chart"}
                  highcharts={HighStock}
                  constructorType={"stockChart"}
                  // options={compareOptions}
                  callback={chart => {
                    if (window.innerWidth > 850) {
                      this.chart = chart;
                    }
                  }}
                  options={{
                    ...compareOptions
                  }}

                  // onClick={ this.printExtremes() }
                />
              </>
            ) : (
              <div
                style={{
                  width: 500,
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div class={"loader"}></div>
              </div>
            )}
            {/* <div>
              <Link to="/crisiscompare/global" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  2008 Global Financial Crisis
                </button>
              </Link>
              <Link to="/crisiscompare/european" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  European Crisis
                </button>
              </Link>
              <Link to="/crisiscompare/taperTantrum" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  Taper Tantrum
                </button>
              </Link>
              <Link to="/crisiscompare/chinaYuan" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  Post China Yuan Evaluation
                </button>
              </Link>
              <Link to="/crisiscompare/nbfc" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  NBFC Crisis
                </button>
              </Link>
              <Link to="/crisiscompare/corona" target="_blank">
                <button onClick={() => {}} className="crisisButton">
                  Corona
                  {"   "}
                </button>
              </Link>
            </div> */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
              class=" research-invest-btn-section "
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important"
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange m-r-15"
                  style={{ backgroundColor: "#ffc20e" }}
                >
                  Request Callback
                </a>
              </Link>
            </div>
            {/* UNCOMMENT */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex"
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>
        </div>
        <div class=" research-invest-section ">
          <div class=" row ">
            <div class=" col s12 m6 offset-m3 ">
              <div
                class=" research-invest-section-content "
                style={{ paddingTop: 0 }}
              >
                {/* <h4>
                  Don't fall behind your goals <br></br>It's time to invest for
                  your better future{" "}
                </h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          id="container"
          style={{
            width: "100%",
            height: 400,
            margin: "0 auto",
            marginTop: 32,
            maxWidth: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div class="loader"></div>
        </div> */}

        <Footer />
      </div>
    );
  }
}

export default HowjamaWorks;
