import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const WebexRedirection = props => {
  useEffect(() => {
    window.location.replace("https://simplygrow.webex.com/meet/ram");
  }, []);
  return null;
};

export default WebexRedirection;
