import React, { Component } from "react";
import "../css/style.css";
import "../css/how-jama-works.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Moneybag from "../assets/Money Bag.svg";
import magicwand from "../assets/magic-wand.svg";
import allocate from "../assets/allocate.svg";
import Howjamaimg from "../assets/Howjamaimg.svg";
import bars from "../assets/bars.svg";
import Header from "./HeaderNew";
import jamabrandlogo from "../assets/Jama_wealth_logo.svg";

// import HomepageBodyContent from "./HomepageBodyContent";
// import Footer from "./Footer";
import FooterNew from "./FooterNew";
import philosophyyllo from "../assets/philosophyyllo.png";
import howjamaimgblk from "../assets/howjamaimgblk.png";
import startimgyllo from "../assets/startimgyllo.png";
// import WOW from "wowjs";
import Introsection from "./Introsection";
import FeatureSection from "./featureSection";
import { Helmet } from "react-helmet";
import HighStock from "highcharts/highstock";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

var to_date;
var from_date;
var nifty_cagr;
var jars_cagr;
var nifty_windows;
var jars_windows;

const options = {
  title: {
    text: "My stock chart"
  },
  series: [
    {
      data: [1, 2, 3]
    }
  ]
};

const crisis = [
  "2008 Global Financial Crisis",
  "European Crisis",
  "Taper Tantrum",
  "Post China Yuan Evaluation",
  "NBFC Crisis",
  "Corona"
];

class CrisisComp extends Component {
  state = {
    data: null,
    from: null,
    to: null,
    selected: null
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.redraw != nextState.redraw;
  }

  printExtremes = () => {
    // console.log("Redraw is: " + this.state.redraw);
    try {
      var new_from = new Date(this.chart.xAxis[0].getExtremes().min);
      var new_to = new Date(this.chart.xAxis[0].getExtremes().max);
      if (
        (this.state.from.getTime() + " ").localeCompare(
          this.chart.xAxis[0].getExtremes().min + " "
        ) != 0
      ) {
        this.setState({ from: new_from, to: new_to }, () => {
          this.selectDay(new_from, true);
          // console.log("from is: " + this.state.from.getTime());
          // console.log("other is: " + this.chart.xAxis[0].getExtremes().min);
          this.update_stats(new_to.getTime(), new_from.getTime());
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  indexOfmin(arr, start, end) {
    if (arr.length === 0) {
      return -1;
    }

    var min = arr[start][1];
    var minIndex = start;
    for (var i = start; i < end + 1; i++) {
      if (arr[i][1] < min) {
        minIndex = i;
        min = arr[i][1];
      }
    }
    return [minIndex, min];
  }
  indexOfmax(arr, start, end) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[start][1];
    var maxIndex = start;

    for (var j = start; j < end + 1; j++) {
      if (arr[j][1] > max) {
        maxIndex = j;
        max = arr[j][1];
      }
    }
    return [maxIndex, max];
  }
  indexOfequal(arr, start, end, beg) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[beg][1];
    var maxIndex = start;

    for (var j = start; j < end + 1; j++) {
      if (arr[j][1] > max) {
        maxIndex = j;
        max = arr[j][1];
        break;
      }
    }
    if (start == beg) {
      max = arr[beg][1];
      maxIndex = start;
    }
    return [maxIndex, max];
  }
  get_future_year_returns(data, years, date) {
    var end = data[date][0] + years * 1000 * 60 * 60 * 24 * 365;
    // console.log(data[data.length-1]);
    var endday = new Date(end);
    // console.log("LOOP DATE is: " + endday);
    if (end > data[data.length - 1][0]) {
      return "-";
    }
    endday = endday.getDay();
    var end_index = data.length - 1;
    if (endday == 0) {
      end = end - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      end = end - 1 * (1000 * 60 * 60 * 24);
    }

    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= end) {
        end_index = data.length - 1 - j;
        break;
        // console.log("START INDEX IS: " + start_index);
      }
    }
    // console.log(
    //   "Return date is: " + data[date][0] + " and " + data[end_index][0]
    // );
    // console.log("Future Value: " + data[end_index][1]);
    // console.log("Present Value: " + data[date][1]);
    var returns = data[end_index][1] / data[date][1] - 1;
    // console.log(years + " Years returns is: " + returns * 100);
    if (date == 3442) {
      returns = 0;
    }
    return Math.round(returns * 100 * 100) / 100 + "%";
  }
  update_crisis_stats(start, trough, peak, end) {
    // console.log("CRISIS START IS: ", start);
    var fromd = new Date(this.state.data[0].data[start][0]);
    var tod = new Date(this.state.data[0].data[end][0]);
    var nifty_trough = this.indexOfmin(this.state.data[0].data, start, end);
    // console.log("NIFTY Trough is: " + nifty_trough);
    // console.log(
    //   "NIFTY Draw Period is: " + this.state.data[0].data[nifty_trough[0]][0]
    // );
    var nifty_peak = this.indexOfmax(
      this.state.data[0].data,
      nifty_trough[0],
      end
    );
    var nifty_recovered = this.indexOfequal(
      this.state.data[0].data,
      nifty_trough[0],
      end,
      start
    );
    var jars_trough = this.indexOfmin(this.state.data[1].data, start, end);
    // console.log("JARS Trough is: " + jars_trough);
    // console.log(
    //   "JARS Draw Period is: " + this.state.data[1].data[jars_trough[0]][0]
    // );
    var jars_peak = this.indexOfmax(
      this.state.data[1].data,
      jars_trough[0],
      end
    );
    var jars_recovered = this.indexOfequal(
      this.state.data[1].data,
      jars_trough[0],
      end,
      start
    );
    var jars_rec =
      this.state.data[1].data[jars_peak[0]][1] /
        this.state.data[1].data[start][1] -
      1;
    if (jars_rec < 0) {
      jars_rec = 0;
    }
    var nifty_rec =
      this.state.data[0].data[nifty_peak[0]][1] /
        this.state.data[0].data[start][1] -
      1;
    if (nifty_rec < 0) {
      nifty_rec = 0;
    }
    // console.log(nifty_trough, nifty_peak, jars_trough, jars_peak);
    // console.log(
    //   "1 year return is: " +
    //     this.get_future_year_returns(this.state.data[1].data, 1, trough)
    // );
    this.setState({
      from: fromd,
      to: tod,
      crisis_from: "" + fromd.toDateString(),
      crisis_to: "" + tod.toDateString(),
      crisis_jars_drawdown_period:
        (this.state.data[0].data[jars_trough[0]][0] -
          this.state.data[0].data[start][0]) /
        (1000 * 60 * 60 * 24),
      crisis_jars_recovery_period:
        (this.state.data[1].data[jars_recovered[0]][0] -
          this.state.data[1].data[jars_trough[0]][0]) /
        (1000 * 60 * 60 * 24),
      crisis_nifty_drawdown_period:
        (this.state.data[0].data[nifty_trough[0]][0] -
          this.state.data[0].data[start][0]) /
        (1000 * 60 * 60 * 24),
      crisis_nifty_recovery_period:
        (this.state.data[1].data[nifty_recovered[0]][0] -
          this.state.data[1].data[nifty_trough[0]][0]) /
        (1000 * 60 * 60 * 24),
      crisis_jars_drawdown:
        "" +
        Math.round(
          (this.state.data[1].data[jars_trough[0]][1] /
            this.state.data[1].data[start][1] -
            1) *
            10000
        ) /
          100 +
        " %",
      crisis_jars_recovery: "" + Math.round(jars_rec * 10000) / 100 + " %",
      crisis_nifty_drawdown:
        "" +
        Math.round(
          (this.state.data[0].data[nifty_trough[0]][1] /
            this.state.data[0].data[start][1] -
            1) *
            10000
        ) /
          100 +
        " %",
      crisis_nifty_recovery: "" + Math.round(nifty_rec * 10000) / 100 + " %",
      jars_crisis_1yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[1].data[start][0] + 1000 * 60 * 60 * 24 * 365,
          this.state.data[1].data[start][0],
          this.state.data[1].data
        )
      ),
      jars_crisis_3yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[1].data[start][0] + 3 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[1].data[start][0],
          this.state.data[1].data
        )
      ),
      jars_crisis_5yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[1].data[start][0] + 5 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[1].data[start][0],
          this.state.data[1].data
        )
      ),
      jars_crisis_10yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[1].data[start][0] + 10 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[1].data[start][0],
          this.state.data[1].data
        )
      ),
      nifty_crisis_1yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[0].data[start][0] + 1000 * 60 * 60 * 24 * 365,
          this.state.data[0].data[start][0],
          this.state.data[0].data
        )
      ),
      nifty_crisis_3yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[0].data[start][0] + 3 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[0].data[start][0],
          this.state.data[0].data
        )
      ),
      nifty_crisis_5yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[0].data[start][0] + 5 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[0].data[start][0],
          this.state.data[0].data
        )
      ),
      nifty_crisis_10yr_returns: this.get_cagr(
        this.get_window(
          this.state.data[0].data[start][0] + 10 * 1000 * 60 * 60 * 24 * 365,
          this.state.data[0].data[start][0],
          this.state.data[0].data
        )
      )
    });
  }

  get_only_navs(data) {
    var onlynav = [];
    for (var i = 0; i < data.length - 1; i++) {
      onlynav.push(data[i][1]);
    }
    return onlynav;
  }
  getMean(data) {
    return (
      data.reduce(function(a, b) {
        return Number(a) + Number(b);
      }) / data.length
    );
  }
  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }
  getSD(data) {
    let m = this.getMean(data);
    return (
      Math.round(
        Math.sqrt(
          data.reduce(function(sq, n) {
            return sq + Math.pow(n - m, 2);
          }, 0) /
            (data.length - 1)
        ) * 1000
      ) / 1000
    );
  }
  getIndexOfDate(data, date) {
    var index = 0;
    for (var j = 0; j < data.length; j++) {
      if ("" + data[j][0] >= "" + date) {
        index = j;
        break;
      }
    }
    // console.log("THE DATE IS:" + date + "AND THE INDEX IS: " + index);
    return parseInt(index);
  }
  get_yearly_sd(data, year, to) {
    var from = to - year * (1000 * 60 * 60 * 24 * 365);
    var todate = new Date(to);
    var fromdate = new Date(from);
    var today = todate.getDay();
    if (today == 0) {
      to = to - 2 * (1000 * 60 * 60 * 24);
    } else if (today == 6) {
      to = to - 1000 * 60 * 60 * 24;
    }
    var fromday = fromdate.getDay();
    if (fromday == 0) {
      from = from - 2 * (1000 * 60 * 60 * 24);
    } else if (fromday == 6) {
      from = from - 1000 * 60 * 60 * 24;
    }
    // console.log("FROM AND TO FOR SD: " + from + " AND " + to);
    var start_index = 0;
    var end_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      if ("" + data[j][0] === "" + from) {
        start_index = j;
        // console.log("SD START INDEX IS: " + start_index);
      } else if ("" + data[j][0] === "" + to) {
        end_index = j;
        // console.log("SD END INDEX IS: " + j);
      }
    }
    return this.getSD(
      this.get_only_navs(data.slice(start_index, end_index + 1))
    );
  }
  get_window(to, from, data) {
    // console.log("Window To date: " + to);
    // console.log("Window from Date: " + from);
    var from_index = 0;
    var to_index = data.length - 1;
    for (var j = 0; j < data.length; j++) {
      // console.log(data.length)
      // console.log("" + data[j][0]);
      if (data[j][0] >= to) {
        to_index = j;
        // console.log("To index is: " + to_index);
        break;
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= from) {
        from_index = j;
        // console.log("From index is: " + from_index);
        break;
      }
    }

    return data.slice(from_index, to_index + 1);
  }
  get_cagr(data) {
    var days = (data[data.length - 1][0] - data[0][0]) / (1000 * 60 * 60 * 24);
    var jars_cagr =
      Math.pow(data[data.length - 1][1] / data[0][1], 1 / (days / 365)) - 1;

    // console.log((data[data.length-1][1]/data[0][1]));
    // console.log((1/(days/365)));
    // console.log("JARS CAGR: " + jars_cagr * 100);
    // nifty_cagr = Math.pow(((niftynav[niftynav.length - 1] - niftynav[0])/niftynav[0]) * 100, (1/(days/365)));
    // console.log(nifty_cagr);
    return Math.round(jars_cagr * 100 * 100) / 100 + "%";
  }

  get_year_returns(data, years, date) {
    var start = date - years * 1000 * 60 * 60 * 24 * 365;
    // console.log(data[data.length - 1]);
    var startday = new Date(start);
    var endday = new Date(date);
    // console.log("LOOP DATE is: " + endday);
    startday = startday.getDay();
    endday = endday.getDay();
    var start_index = 0;
    var end_index = data.length - 1;
    if ((startday == 0) | (startday == 6)) {
      start = start + 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 0) {
      date = date - 2 * (1000 * 60 * 60 * 24);
    } else if (endday == 6) {
      date = date - 1 * (1000 * 60 * 60 * 24);
    }
    // console.log("Return date is: " + date + " and " + start);
    for (var j = 0; j < data.length; j++) {
      if (data[j][0] >= start) {
        start_index = j;
        break;
        // console.log("START INDEX IS: " + start_index);
      }
    }
    for (var j = 0; j < data.length; j++) {
      if (data[data.length - 1 - j][0] <= date) {
        end_index = data.length - 1 - j;
        break;
        // console.log("END INDEX IS: " + j);
      }
    }
    // console.log("Future Value: " + data[end_index][1]);
    // console.log("Present Value: " + data[start_index][1]);
    var returns =
      (data[end_index][1] - data[start_index][1]) / data[start_index][1];
    // console.log(years + " Years returns is: " + returns * 100);
    return Math.round(returns * 100 * 100) / 100 + "%";
  }
  update_stats_new(todate, fromdate) {
    this.setState({
      redraw: 0,
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
      jars_1yr_returns: this.get_year_returns(
        this.state.data[1].data,
        1,
        todate
      ),
      jars_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        3,
        todate
      ),
      jars_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        5,
        todate
      ),
      jars_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        10,
        todate
      ),
      nifty_1yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        1,
        todate
      ),
      nifty_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        3,
        todate
      ),
      nifty_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        5,
        todate
      ),
      nifty_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        10,
        todate
      ),
      jars1yr_sd: this.get_yearly_sd(this.state.data[1].data, 1, todate),
      jars3yr_sd: this.get_yearly_sd(this.state.data[1].data, 3, todate),
      jars5yr_sd: this.get_yearly_sd(this.state.data[1].data, 5, todate),
      jars10yr_sd: this.get_yearly_sd(this.state.data[1].data, 10, todate),
      nifty1yr_sd: this.get_yearly_sd(this.state.data[0].data, 1, todate),
      nifty3yr_sd: this.get_yearly_sd(this.state.data[0].data, 3, todate),
      nifty5yr_sd: this.get_yearly_sd(this.state.data[0].data, 5, todate),
      nifty10yr_sd: this.get_yearly_sd(this.state.data[0].data, 10, todate)
    });
  }
  update_stats(todate, fromdate) {
    // this.setState({redraw : 0},() => console.log("Redraw Changed"));
    this.setState({
      nifty_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[0].data)
      ),
      jars_cagr: this.get_cagr(
        this.get_window(todate, fromdate, this.state.data[1].data)
      ),
      jars_1yr_returns: this.get_year_returns(
        this.state.data[1].data,
        1,
        todate
      ),
      jars_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        3,
        todate
      ),
      jars_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        5,
        todate
      ),
      jars_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[1].data),
        10,
        todate
      ),
      nifty_1yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        1,
        todate
      ),
      nifty_3yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        3,
        todate
      ),
      nifty_5yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        5,
        todate
      ),
      nifty_10yr_returns: this.get_year_returns(
        this.get_window(todate, fromdate, this.state.data[0].data),
        10,
        todate
      ),
      jars1yr_sd: this.get_yearly_sd(this.state.data[1].data, 1, todate),
      jars3yr_sd: this.get_yearly_sd(this.state.data[1].data, 3, todate),
      jars5yr_sd: this.get_yearly_sd(this.state.data[1].data, 5, todate),
      jars10yr_sd: this.get_yearly_sd(this.state.data[1].data, 10, todate),
      nifty1yr_sd: this.get_yearly_sd(this.state.data[0].data, 1, todate),
      nifty3yr_sd: this.get_yearly_sd(this.state.data[0].data, 3, todate),
      nifty5yr_sd: this.get_yearly_sd(this.state.data[0].data, 5, todate),
      nifty10yr_sd: this.get_yearly_sd(this.state.data[0].data, 10, todate),
      redraw: this.state.redraw + 1
    });
  }

  handleFromChange = day => {
    this.setState({ from: day }, () => {
      this.selectDay(day, true);
      this.update_stats(this.state.to - 23400000, this.state.from - 23400000);
    });
  };

  handleToChange = day => {
    this.setState({ to: day }, () => {
      this.selectDay(day);
      this.update_stats(this.state.to - 23400000, this.state.from - 23400000);
    });
    this.chart.xAxis[0].setExtremes(
      this.state.data[0].data[400][0],
      this.state.data[0].data[800][0]
    );
  };
  // console.log(from);
  selectDay = (time, isMin) => {
    // console.log("time", time);
    // console.log("time", time.getTime());
    let timestamp;
    if (time) {
      timestamp = time.getTime();
    } else {
      if (isMin) {
        timestamp = this.state.from;
      } else {
        timestamp = this.state.to;
      }
    }
    // console.log("this.internalChart", this.chart);
    const xAxis = this.chart.xAxis[0];

    xAxis.setExtremes(
      isMin ? timestamp : xAxis.min,
      isMin ? xAxis.max : timestamp
    );
    this.setState(isMin ? { minInput: false } : { maxInput: false });
  };

  onChange = date => this.setState({ date });
  async componentDidMount() {
    // new WOW.WOW().init();
    // window.scrollTo(0, 0);
    // console.log("props", this.props.match.params.crisis);

    const jars = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/spark_nav_values.json"
    );

    const nifty = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty_nav_values.json"
    );
    const nifty50TRI = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/bse_smlcap_nav_values.json"
    );
    const nifty50 = await axios.get(
      "https://jamaapptest2.s3.amazonaws.com/nifty50_nav_values.json"
    );

    // const jars = await axios.get(
    //   "https://api.jsonbin.io/b/5e872a85dd6c3c63eaed85d1"
    // );

    // const nifty = await axios.get(
    //   "https://api.jsonbin.io/b/5e872e4a4f5f49640ba33e94"
    // );
    // const nifty50TRI = await axios.get(
    //   "https://api.jsonbin.io/b/5e872e1293960d63f0782864"
    // );
    // const nifty50 = await axios.get(
    //   "https://api.jsonbin.io/b/5e872a1693960d63f0782698"
    // );

    const getDates = nifty.data;

    this.setState(
      {
        data: [
          // {
          //   name: "Nifty",
          //   data: nifty.data,
          //   // lineColor: 'transparent',
          //   visible: true,
          // },
          {
            name: "BSE Smallcap",
            data: nifty50TRI.data,
            color: "red"
          },
          {
            name: "SPARK",
            data: jars.data,
            color: "#410daa",
            id: "SPARK"
          },
          // {
          //   name: "Nifty 50",
          //   data: nifty50.data,
          //   // color: 'transparent',
          //   lineColor: "transparent",
          // },
          {
            name: "SPARK Deployment",
            type: "flags",
            data: [
              {
                x: Date.UTC(2021, 5, 1),
                title: "Deployment",
                text: "From this date SPARK was deployed in the market",
              },
            ],
            onSeries: "JAMA",
            shape: "squarepin",
            height : 15,
          },
        ],
        from: new Date(nifty.data[0][0]),
        to: new Date(getDates.pop()[0]),
        nifty_cagr: this.get_cagr(nifty.data),
        jars_cagr: this.get_cagr(jars.data),
        redraw: 1

        // from: moment(nifty.data[0][0]),
        // to: moment(getDates.pop()[0])
      },
      () => {
        const crisis = this.props.match.params.crisis;
        const { selectedCrisis } = this.props.location.crisisProps || {
          selectedCrisis: 0
        };
        this.updateCrisis(selectedCrisis);
        // this.updateCrisis(0);
      }
    );
    this.update_stats(this.state.to.getTime(), this.state.from.getTime());
  }

  updateCrisis = crisis => {
    if (crisis == 0) {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1207656000000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1289217600000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1207656000000),
        652,
        797,
        this.getIndexOfDate(this.state.data[0].data, 1289217600000)
      );
      // this.setState({ crisis_name: "2008 Global Financial Crisis" });

      // this.chart.xAxis[0].setExtremes(
      //   this.state.data[0].data[435][0],
      //   this.state.data[0].data[822][0]
      // );
      // this.update_crisis_stats(435, 652, 797, 822);
      this.setState({
        crisis_name: "2008 Global Financial Crisis",
        selected: 0
      });
    } else if (crisis == 1) {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1293796800000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1366891200000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1293796800000),
        1420,
        1463,
        this.getIndexOfDate(this.state.data[0].data, 1366891200000)
      );

      // this.chart.xAxis[0].setExtremes(
      //   this.state.data[0].data[1186][0],
      //   this.state.data[0].data[1471][0]
      // );
      // this.update_crisis_stats(1186, 1420, 1463, 1471);
      this.setState({ crisis_name: "European Crisis", selected: 1 });
    } else if (crisis == 2) {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1356609600000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1388577600000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1356609600000),
        1835,
        1903,
        this.getIndexOfDate(this.state.data[0].data, 1388577600000)
      );
      this.setState({ crisis_name: "Taper Tantrum", selected: 2 });
    } else if (crisis == 3) {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1431432000000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1472731200000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1431432000000),
        2446,
        2570,
        this.getIndexOfDate(this.state.data[0].data, 1472731200000)
      );
      this.setState({ crisis_name: "Post China Yuan Evaluation", selected: 3 });
    } else if (crisis == "4") {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1534420800000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1579694400000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1534420800000),
        3096,
        3206,
        this.getIndexOfDate(this.state.data[0].data, 1579694400000)
      );
      // this.setState({ crisis_name: "NBFC Crisis" });

      // this.chart.xAxis[0].setExtremes(
      //   this.state.data[0].data[3051][0],
      //   this.state.data[0].data[3221][0]
      // );
      // this.update_crisis_stats(3051, 3096, 3206, 3221);
      this.setState({ crisis_name: "NBFC Crisis", selected: 4 });
    } else if (crisis == "5") {
      this.chart.xAxis[0].setExtremes(
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1580558400000)
        ][0],
        this.state.data[0].data[
          this.getIndexOfDate(this.state.data[0].data, 1598875200000)
        ][0]
      );
      this.update_crisis_stats(
        this.getIndexOfDate(this.state.data[0].data, 1580558400000),
        3444,
        3444,
        this.getIndexOfDate(this.state.data[0].data, 1598875200000)
      );
      this.setState({ crisis_name: "Corona Outbreak", selected: 5 });
    }
  };

  buttonClick = value => {
    let newDate;
    if (value == -1) {
      newDate = new Date(new Date().getFullYear(), 0, 1);
    } else {
      newDate = moment().subtract(value, "months");
    }
    this.setState({ from: new Date(newDate) });
  };
  // to_date = this.state.to;
  // from_date = this.state.from;
  render() {
    var redraw1 = 1;
    var range_dict = [9, 4, 9, 5, 9, 6, 9, 7, 9, 9, 8];
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    var default_range = this.getUrlVars()["x"];
    const { from, to, selected } = this.state;
    const compareOptions = {
      credits: {
        enabled: false
      },
      chart: {
        renderTo: "container",
        height: 380,
        events: {
          redraw: () => {
            this.printExtremes();
          }
        }
      },
      exporting: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        selected: range_dict[default_range],
        enabled: false,
        inputEnabled: false,
        verticalAlign: "bottom",
        buttonTheme: {
          // styles for the buttons
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 8,
          style: {
            color: "#039",
            fontWeight: "bold"
          },
          states: {
            hover: {},
            select: {
              fill: "#039",
              style: {
                color: "white"
              }
            }
            // disabled: { ... }
          }
        },
        inputBoxBorderColor: "gray",
        inputBoxWidth: 120,
        inputBoxHeight: 18,
        inputStyle: {
          color: "#039",
          fontWeight: "bold"
        },
        labelStyle: {
          color: "silver",
          fontWeight: "bold"
        },
        buttons: [
          {
            type: "month",
            count: 1,
            text: "1m",
            events: {
              click: () => this.buttonClick(1)
            }
          },
          {
            type: "month",
            count: 3,
            text: "3m",
            events: {
              click: () => this.buttonClick(3)
            }
          },
          {
            type: "month",
            count: 6,
            text: "6m",
            events: {
              click: () => this.buttonClick(6)
            }
          },
          {
            type: "ytd",
            text: "YTD",
            events: {
              click: () => this.buttonClick(-1)
            }
          },
          {
            type: "year",
            count: 1,
            text: "1y",
            events: {
              click: () => this.buttonClick(12)
            }
          },
          {
            type: "year",
            count: 3,
            text: "3y",
            events: {
              click: () => this.buttonClick(36)
            }
          },
          {
            type: "year",
            count: 5,
            text: "5y",
            events: {
              click: () => this.buttonClick(60)
            }
          },
          {
            type: "year",
            count: 7,
            text: "7y",
            events: {
              click: () => this.buttonClick(84)
            }
          },
          {
            type: "year",
            count: 10,
            text: "10y",
            events: {
              click: () => this.buttonClick(120)
            }
          },
          {
            type: "all",
            text: "All",
            events: {
              click: () => this.buttonClick(0)
            }
          }
        ]
      },
      yAxis: {
        labels: {
          align: "left",
          formatter: function() {
            return (this.value > 0 ? " + " : "") + this.value + "%";
          }
        },
        plotLines: [
          {
            value: 0,
            width: 2,
            color: "silver"
          }
        ]
      },
      xAxis: {
        events: {
          setExtremes: function(e) {
            // console.log("this", this);
            // console.log(e);
          }
        }
      },
      plotOptions: {
        series: {
          compare: "percent",
          showInNavigator: true,
          compareStart: true
        },
        flags: {
          height: 30
        }
      },
      lang: {
        thousandsSep: ","
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color};font-weight:500;">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
        valueDecimals: 2,
        split: true
      },
      series: this.state.data
    };

    return (
      <div className="compareWrapper">
        <Header />
        <Helmet>
          <title>
            {" "}
            Compare 2008 Global Financial Crisis with JWEL Portfolio{" "}
          </title>
          <meta
            name="description"
            content="Compare SPARK with 2008 Global Financial Crisis"
          ></meta>
        </Helmet>

        {/* <div
          class="jars-section-title-heading"
          style={{ margin: "100px auto 0", textAlign: "center" }}
        >
          <h5>Compare Jamā Wealth Equity with Nifty 50</h5>
        </div> */}

        {this.state.crisis_name && (
          <div className="crisisName">
            <h1 style={{ fontSize: 36 }}>
              Compare JWEL with {this.state.crisis_name}
            </h1>
          </div>
        )}

        <div>
          <select>
            {crisis.map((crisisItem, index) => (
              <option>{crisisItem}</option>
            ))}
          </select>
        </div>
        {/* <div className="crisisContainer">
          {crisis.map((crisisItem, index) => (
            <div
              onClick={() => {
                this.updateCrisis(index);
              }}
            >
              <p
                style={{
                  color: selected === index ? "#410daa" : "#bbb",
                }}
              >
                {crisisItem}
              </p>
              {selected == index && (
                <div
                  style={{
                    width: 60,
                    height: 2,
                    backgroundColor: "#410daa",
                    marginLeft: 8,
                  }}
                ></div>
              )}
            </div>
          ))}
        </div> */}
        <div
          style={{ alignItems: "center", justifyContent: "center" }}
          className="crisisContainer"
        >
          {crisis.map((crisisItem, index) => (
            <div
              style={{ margin: 20, fontSize: "15px" }}
              onClick={() => {
                this.updateCrisis(index);
              }}
            >
              <p
                style={{
                  color: selected === index ? "#410daa" : "#bbb"
                }}
              >
                {crisisItem}
              </p>
              {selected == index && (
                <div
                  style={{
                    width: 60,
                    height: 2,
                    backgroundColor: "#410daa",
                    marginLeft: 8
                  }}
                ></div>
              )}
            </div>
          ))}
        </div>
        <div className="compareTableGraph">
          <div
            style={{ width: "90%", float: "left", margin: "0 auto" }}
            className="secondaryWrapper"
          >
            {/* <p
              style={{
                margin: "0 auto",
                textAlign: "center",
                alignItems: "center",
                margintop: "auto",
                marginBottom: 8,
                width: "80%",
                padding: 0,
                fontWeight: "bold",
              }}
            >
              {this.state.crisis_name}
            </p> */}
            {this.state.data && (
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  maxWidth: 500,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 16
                  // float: "right"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    float: "right"
                  }}
                >
                  <p style={{ marginRight: 8, color: "#bbb" }}>From</p>
                  <p
                    style={{
                      border: "1px solid #ddd",
                      width: 100,
                      padding: 4,
                      borderRadius: 8,
                      textAlign: "center"
                      // height: 30
                    }}
                  >
                    {moment(from).format("YYYY-MM-DD")}
                  </p>
                  {/* <DayPickerInput
                    style={{
                      border: "1px solid #ddd",
                      width: 100,
                      padding: 4,
                      borderRadius: 8,
                      // height: 30
                    }}
                    inputProps={{
                      style: {
                        border: "none",
                        height: "auto",
                        margin: 0,
                        textAlign: "center",
                        fontSize: 12,
                      },
                      readonly: true,
                    }}
                    onDayChange={this.handleFromChange}
                    value={from}
                  /> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <p style={{ marginRight: 8, color: "#bbb", marginLeft: 32 }}>
                    To
                  </p>
                  <p
                    style={{
                      border: "1px solid #ddd",
                      width: 100,
                      padding: 4,
                      borderRadius: 8,
                      textAlign: "center"
                      // height: 30
                    }}
                  >
                    {moment(to).format("YYYY-MM-DD")}
                  </p>
                  {/* <DayPickerInput
                    style={{
                      border: "1px solid #ddd",
                      width: 100,
                      padding: 4,
                      borderRadius: 8,
                      // height: 30
                    }}
                    inputProps={{
                      style: {
                        border: "none",
                        height: "auto",
                        margin: 0,
                        textAlign: "center",
                        fontSize: 12,
                      },
                      readonly: true,
                    }}
                    onDayChange={this.handleToChange}
                    value={to}
                  /> */}
                </div>
              </div>
            )}
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 700,
                float: "right"
              }}
              className="mobileGraphWrapper"
            >
              <div
                style={{ float: "right", display: "none" }}
                className="crisisContainer"
              >
                <h7
                  style={{
                    color: "#121212",
                    fontWeight: "bold",
                    float: "right"
                  }}
                >
                  <b>Previous Bear Markets: </b>
                </h7>

                <div
                  style={{
                    width: 200,
                    border: "1px solid #410daa",
                    borderRadius: 8,
                    height: 36,
                    position: "relative"
                  }}
                  onClick={() =>
                    this.setState({
                      showDropDown: !this.state.showDropDown
                    })
                  }
                >
                  <p style={{ height: 24 }}>{this.state.selectedCrisis}</p>

                  {this.state.showDropDown && (
                    <div
                      style={{
                        zIndex: 100,
                        position: "absolute",
                        backgroundColor: "grey",
                        color: "#fff",
                        borderRadius: 8
                      }}
                    >
                      {crisis.map((crisisItem, index) => {
                        return (
                          <p
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                selectedCrisis: crisisItem.name
                              });
                              this.DispPathbyName(crisisItem.name);
                            }}
                          >
                            {crisisItem.name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                  {/* <label>
                    <input
                      list="crisisdatalist"
                      id="inputamc"
                      class="input-amc"
                      onChange={(e) => {
                        // console.log(e.target.value);
                        this.DispPathbyName(e.target.value);
                      }}
                    />
                  </label>
                  <datalist id="crisisdatalist"></datalist> */}
                </div>
              </div>
              <br></br>
              <br></br>

              {this.state.data ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 32
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16
                      }}
                    >
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "#410daa",
                          marginRight: 8
                        }}
                      ></div>
                      <p>SPARK</p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: 4,
                          backgroundColor: "red",
                          marginRight: 8
                        }}
                      ></div>
                      <p>BSE Smallcap</p>
                    </div>
                  </div>
                  <HighchartsReact
                    style={{ heigh: 1000, zIndex: -1 }}
                    ref={"chart"}
                    highcharts={HighStock}
                    constructorType={"stockChart"}
                    // options={compareOptions}
                    callback={chart => {
                      if (window.innerWidth <= 850) {
                        this.chart = chart;
                      }
                    }}
                    options={{
                      ...compareOptions
                    }}

                    // onClick={ this.printExtremes() }
                  />
                </>
              ) : (
                <div
                  style={{
                    width: 500,
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div class={"loader"}></div>
                </div>
              )}
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5
                }}
                class=" research-invest-btn-section "
              >
                <Link style={{ width: "85%" }} to="/contactus">
                  <a
                    style={{
                      float: "center",
                      borderRadius: "10px!important",
                      backgroundColor: "#410DAA", color: "#fff"
                    }}
                    class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                  >
                    Request Callback
                  </a>
                </Link>
              </div>
              {/* UNCOMMENT */}
              <div
                style={{
                  float: "center",
                  width: "100%",
                  display: "flex",
                  borderRadius: "10px!important",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p
                  style={{
                    width: "84%",
                    color: "#410daa",
                    justifyContent: "center",
                    float: "center",
                    display: "flex"
                  }}
                >
                  Get a free health check on stock portfolio from advisor.
                </p>
              </div>
            </div>
            <div
              style={{
                width: "80%",
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16
              }}
              className="crisisTable"
            >
              <table
                style={{
                  margin: "0 auto",
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto",
                  padding: 50
                }}
              >
                <tr
                  style={{ paddingBottom: 8, borderBottom: "2px solid #ddd" }}
                >
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    Statistic
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>
                    SPARK
                  </td>
                  <td style={{ padding: "0 0 8px 0", color: "#bbb" }}>BSE Smallcap</td>
                </tr>
                <tr>
                  <td>Drawdown</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.crisis_jars_drawdown}
                  </td>
                  <td>{this.state.crisis_nifty_drawdown}</td>
                </tr>
                <tr>
                  <td>Drawdown Period</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.crisis_jars_drawdown_period}
                  </td>
                  <td>{this.state.crisis_nifty_drawdown_period}</td>
                </tr>
                <tr>
                  <td>Recovery</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.crisis_jars_recovery}
                  </td>
                  <td>{this.state.crisis_nifty_recovery}</td>
                </tr>
                <tr>
                  <td>Recovery Period</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.crisis_jars_recovery_period}
                  </td>
                  <td>{this.state.crisis_nifty_recovery_period}</td>
                </tr>
              </table>
            </div>
            <br></br>
            <div
              style={{
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto"
              }}
            >
              <p className="performanceHeading">
                Performance After Bear Market
              </p>
            </div>
            <div
              style={{
                width: "80%",
                border: "2px solid #ddd",
                borderRadius: 16,
                margin: "0 auto 16px",
                padding: 16
              }}
            >
              <table
                id="std_table"
                style={{
                  margin: "0 auto",
                  float: "centre",
                  alignItems: "center",
                  margintop: "auto"
                }}
              >
                <tr style={{ borderBottom: "2px solid #ddd" }}>
                  <td style={{ paddingBottom: "0 0 8px 0", color: "#bbb" }}>
                    Duration After Crisis
                  </td>
                  <td style={{ paddingBottom: "0 0 8px 0", color: "#bbb" }}>
                    SPARK Returns
                  </td>
                  <td style={{ paddingBottom: "0 0 8px 0", color: "#bbb" }}>
                    BSE Smallcap Returns
                  </td>
                </tr>
                <tr>
                  <td>1 Year</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.jars_crisis_1yr_returns}
                  </td>
                  <td style={{ color: "#121212" }}>
                    {this.state.nifty_crisis_1yr_returns}
                  </td>
                </tr>
                <tr>
                  <td>3 Year</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.jars_crisis_3yr_returns}
                  </td>
                  <td style={{ color: "#121212" }}>
                    {this.state.nifty_crisis_3yr_returns}
                  </td>
                </tr>
                <tr>
                  <td>5 Year</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.jars_crisis_5yr_returns}
                  </td>
                  <td style={{ color: "#121212" }}>
                    {this.state.nifty_crisis_5yr_returns}
                  </td>
                </tr>
                <tr>
                  <td>10 Year</td>
                  <td style={{ color: "#410DAA" }} className="tableContentBold">
                    {this.state.jars_crisis_10yr_returns}
                  </td>
                  <td style={{ color: "#121212" }}>
                    {this.state.nifty_crisis_10yr_returns}
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <br />
            <div>
              <Link to="/SparkCompare" target="_blank" className="btn-tools">
                <a>Compare SPARK with BSE Smallcap</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/SparkCompareMF" target="_blank" className="btn-tools">
                <a>Compare Mutual Funds Performance with JWEL</a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
              <Link to="/SIP2" target="_blank" className="btn-tools">
                <a style={{ borderBottom: "none" }}>
                  Compare SIP Performance of JWEL vs Nifty
                </a>
                <a className="toolsArrow">
                  <b>&#x203A;</b>
                </a>
              </Link>
            </div>
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
              class=" research-invest-btn-section mobCTA"
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important",
                    backgroundColor: "#410DAA", color: "#fff"
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange m-r-15 request-callback"
                >
                  Request Callback
                </a>
              </Link>
            </div>
            {/* UNCOMMENT */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center"
              }}
              className="mobCTA"
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex"
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              margin: "0 auto",
              maxWidth: 700,
              float: "right"
            }}
            className="graphWrapper"
          >
            {this.state.data ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 32
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 16
                    }}
                  >
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "#410daa",
                        marginRight: 8
                      }}
                    ></div>
                    <p>SPARK</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 4,
                        backgroundColor: "red",
                        marginRight: 8
                      }}
                    ></div>
                    <p>BSE Smallcap</p>
                  </div>
                </div>
                <HighchartsReact
                  style={{ heigh: 1000 }}
                  ref={"chart"}
                  highcharts={HighStock}
                  constructorType={"stockChart"}
                  // options={compareOptions}
                  callback={chart => {
                    if (window.innerWidth > 850) {
                      this.chart = chart;
                    }
                  }}
                  options={{
                    ...compareOptions
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  width: 500,
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div class={"loader"}></div>
              </div>
            )}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
              class=" research-invest-btn-section "
            >
              <Link style={{ width: "85%" }} to="/contactus">
                <a
                  style={{
                    float: "center",
                    borderRadius: "10px!important",
                    backgroundColor: "#410DAA", color: "#fff"
                  }}
                  class="waves-effect waves-light btn btn-large btn-orange-new m-r-15 request-callback"
                >
                  Request Callback
                </a>
              </Link>
            </div>
            {/* UNCOMMENT */}
            <div
              style={{
                float: "center",
                width: "100%",
                display: "flex",
                borderRadius: "10px!important",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <p
                style={{
                  width: "84%",
                  color: "#410daa",
                  justifyContent: "center",
                  float: "center",
                  display: "flex"
                }}
              >
                Get a free health check on stock portfolio from advisor.
              </p>
            </div>
          </div>
        </div>
        <div class=" research-invest-section ">
          <div class=" row ">
            <div class=" col s12 m6 offset-m3 ">
              <div
                class=" research-invest-section-content "
                style={{ paddingTop: 0 }}
              >
                {/* <h4>
                  Don't fall behind your goals <br></br>It's time to invest for
                  your better future{" "}
                </h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          id="container"
          style={{
            width: "100%",
            height: 400,
            margin: "0 auto",
            marginTop: 32,
            maxWidth: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div class="loader"></div>
        </div> */}

        <FooterNew />
      </div>
    );
  }
}
// console.log("from date is:" + from);
// console.log("to date is:" + to);

export default CrisisComp;
