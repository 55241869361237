import React, { useEffect } from "react";

const OpenZerodhaRedirection = props => {
  useEffect(() => {
    window.location.replace("https://zerodha.com/open-account?c=TY9422");
  }, []);
  return null;
};

export default OpenZerodhaRedirection;
