import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import cert_9001 from "../assets/pdf/cert_9001.pdf";
import cert_27001 from "../assets/pdf/cert_27001.pdf";
import dipp_pdf from "../assets/pdf/dipp.pdf";
import sebi_pdf from "../assets/pdf/sebi.pdf";

export default class SEBICertificates extends React.Component {
  constructor(props) {
    super(props);
  }

  //Downloading file using axios -- not working
  handleClick = async (file_path, file_name) => {
    // console.log("click");
    fetch(encodeURI(file_path), {
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      }
    })
      .then(response => response.blob())
      .then(response => {
        const blob = new Blob([response], { type: "application/pdf" });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "file.pdf";
        document.body.appendChild(a);
        a.click();
      });
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          maxWidth: 1300,

          paddingTop: 35
        }}
      >
        <div class="jars-section-title-heading" style={{ marginBottom: 50 }}>
          <h4>You can rely on us</h4>
        </div>
        <div class="row">
          <div
            className="col m3 s12 border-right  certificate-item"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <LazyLoadImage
              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/ISO-9001.png"
              style={{ width: 120, height: 60 }}
              alt="ISO-27001"
              className="featuredImages"
            />
            <span className="item-title">ISO 9001 Certified</span>
            <span className="item-description">Quality Management System</span>
            <Link
              to={cert_9001}
              target="_blank"
              download="jama_iso_9001_certificate.pdf"
              download
            >
              <span className="attachment">View Certificate</span>
            </Link>
            <span className="border-bottom"></span>
          </div>
          <div className="col m3 s12 border-right  certificate-item">
            <LazyLoadImage
              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/ISO-27001.png"
              style={{ width: 120, height: 60 }}
              alt="ISO-9001"
              className="featuredImages"
            />
            <span className="item-title">ISO 27001 Certified</span>
            <span className="item-description">
              Information Security Management System
            </span>
            <Link
              to={cert_27001}
              target="_blank"
              download="jama_iso_27001_certificate.pdf"
              download
            >
              <span className="attachment">View Certificate</span>
            </Link>
            <span className="border-bottom"></span>
          </div>
          <div className="col m3 s12 border-right  certificate-item">
            <LazyLoadImage
              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/govt.png"
              style={{ width: 70, height: 60, marginTop: 10 }}
              alt="GOVT"
              className="featuredImages"
            />
            <span className="item-title">GoI DIPP Registered</span>
            <span className="item-description">
              Department of Industrial Policy and Promotion Ministry of Commerce
              and Industry
            </span>
            <span className="item-description">Government of India</span>
            <Link
              to={dipp_pdf}
              target="_blank"
              download="jama_dipp_certificate.pdf"
              download
            >
              <span className="attachment">View Registration</span>
            </Link>
            <span className="border-bottom"></span>
          </div>
          <div className="col m3 s12 certificate-item">
            <LazyLoadImage
              src="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/SEBI.png"
              style={{ width: 70, height: 70 }}
              alt="SEBI"
              className="featuredImages"
            />
            <span className="item-title">
              SEBI Registered Investment Advisors
            </span>
            <span className="item-description">
              Securities and Exchange Board of India
            </span>

            <Link
              to={sebi_pdf}
              target="_blank"
              download="jama_sebi_certificate.pdf"
              download
            >
              <span className="attachment">View Certificate</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
