import React, { Component } from "react";
import jamabrandlogo from "../assets/Jama_wealth_logo.svg";
import { Link, NavLink, BrowserRouter as Router } from "react-router-dom";
import "../css/style.css";
import "../css/headernew.scss";
import Sidebar from "./Sidebar";
import { BrowserView, MobileView } from "react-device-detect";
import  DropDownMenu  from "./DropDownMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoneNumber: false,
      showDropDown:false,
      showDropDown1:false,
      showDropDown2:false

    };
  }
  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get("utm_source");
    const medium = params.get("utm_medium");
    const campaign = params.get("utm_campaign");
    if (source && source.length > 0) {
      sessionStorage.setItem("utm_source", source);
    }
    if (medium && medium.length > 0) {
      sessionStorage.setItem("utm_channel", medium);
    }
    if (medium && campaign.length > 0) {
      sessionStorage.setItem("utm_campaign", campaign);
    }
  }

  render() {
    return (
      <div>
        {this.state.showDropDownMenu && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 5,
              right: 5,
              zIndex: 10,
              backgroundColor: "#fff",
              marginTop: -25,
              width: "95%",
              alignSelf: "center",
              paddingTop: 15,
              paddingBottom: 15
            }}
            class="dropdownMenu shadow-menu"
          >
            <DropDownMenu />
          </div>
        )}
        <nav className="row white header-bg-shadow" role="navigation">
          <div
            class="nav-wrapper container-fluid custom-width-header"
            style={{ maxWidth: 1280, margin: "0 auto" }}
          >
            {/* <Sidebar /> */}
            <a className="navbar-brand" href="https://www.jamawealth.com/">
              {/* <Link to="/">
                {" "} */}
              <img
                className="brand-logo-img"
                src={
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png"
                }
                style={{ width: 140, height: 25 }}
              />
              {/* </Link> */}
            </a>
            <ul className="right hide-on-med-and-up nav-pills">
              <li>
                <a
                  onClick={() => {
                    this.setState({
                      showDropDownMenu: !this.state.showDropDownMenu
                    });
                  }}
                >
                  <i
                    class="fa fa-bars"
                    aria-hidden="true"
                    style={{ fontSize: 18 }}
                  ></i>
                </a>
              </li>
            </ul>
            <ul className="right hide-on-med-and-down nav-pills">
              {/* UNCOMMENT */}
              <li>
                <a
                  onClick={() => {
                    this.setState({
                      showDropDown: !this.state.showDropDown,
                      showDropDown1: false,
                      showDropDown2: false
                    });
                  }}
                  class="active navitem"
                >
                  Company
                  <span style={{paddingLeft:3}} ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/dropdown.png" /></span>
                </a>
                {this.state.showDropDown && (
          <div 
            style={{
              position:"absolute",
              top:50,
              marginLeft:20,
              padding:0,
              zIndex:10,
             }} class="drop_box">
              <div>
              <a href="https://jamawealth.com/about-us/">About Us</a>
              </div>
              <div class="sec_mar">
              <a href="https://jamawealth.com/investment-philosophy/">Investment Philosophy</a>
            </div>
            </div>
        )}
              </li>
              <li>
                <a
                  onClick={() => {
                    this.setState({
                      showDropDown1: !this.state.showDropDown1,
                      showDropDown: false,
                      showDropDown2: false
                    });
                  }}
                  class="active navitem"
                >
                  Equity Advisory 
                  <span style={{paddingLeft:3}} ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/dropdown.png" /></span>
                </a>
                {this.state.showDropDown1 && (
          <div 
            style={{
              position:"absolute",
              top:50,
              marginLeft:20,
              padding:0,
              zIndex:10,
             }} class="drop_box">
              <div >
              <a href="https://jamawealth.com/spark/">
                <img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22079.png" style={{paddingRight:10}}/>Spark 650</a>
              </div>
              <div class="sec_mar" style={{borderTopWidth:1,borderTopColor:"#9192a35c"}}>
               
              <a href="https://jamawealth.com/jewel/" ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22081.png" style={{paddingRight:10}} />Jewel 350</a>
            </div>
            </div>
        )}
              </li>
              <li>
                <a
                  onClick={() => {
                    this.setState({
                      showDropDown2: !this.state.showDropDown2,
                      showDropDown: false,
                      showDropDown1: false
                    });
                  }}
                  
                  class="active navitem"
                >
                  Performance
                  <span style={{paddingLeft:3}} ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/dropdown.png" /></span>
                </a>
                {this.state.showDropDown2 && (
          <div 
            style={{
              position:"absolute",
              top:50,
              marginLeft:20,
              padding:0,
              zIndex:10,
             }} class="drop_box">
              <div >
              <a href="https://performance.jamawealth.com/JewelCompare">
                <img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22081.png" style={{paddingRight:10}}/>Jewel 350</a>
              </div>
              <div class="sec_mar" style={{borderTopWidth:1,borderTopColor:"#9192a35c"}}>
               
              <a href="https://performance.jamawealth.com/SparkCompare" ><img src="https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/Group+22079.png" style={{paddingRight:10}} />Spark 650</a>
            </div>
            </div>
        )}
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/pricing"
                  class="active navitem"
                >
                  pricing
                </a>
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/investor-center"
                  class="active navitem"
                >
                  Investor Center
                </a>
              </li>
              {/* UNCOMMENT */}
              <li>
                <a href="https://jamawealth.com/blog/" class="active navitem">
                  Insights
                </a>
              </li>
              <li>
                <a
                  href="https://app.jamawealth.com/"
                  class="active navitem mmarr"
                >
                  Sign In
                </a>
              </li>
              <li>
                <a
                  href="https://www.jamawealth.com/contact-us"
                  class="header-get-started"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div
          dangerouslySetInnerHTML={{
            __html: `<script>
    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    var source = getParameterByName('utm_source');
    var medium = getParameterByName('utm_medium');
    var campaign = getParameterByName('utm_campaign');
    var parent_source = document.referrer;
    var host = "";

    if(parent_source.length > 0){
        var parts = document.referrer.split('://')[1].split('/');
        var protocol = document.referrer.split('://')[0];
        var host = parts[0];
        host = host.replace("www."," ").replace(".com"," ");
        var pathName = parts.slice(1).join('/')
    }
    if(source.length > 0){
        sessionStorage.setItem("utm_source", source);
    }
    else if(host.length){
        sessionStorage.setItem("utm_source", host);
    }
    if(medium.length > 0){
        sessionStorage.setItem("utm_channel", medium);
    }
    if(campaign.length > 0){
        sessionStorage.setItem("utm_campaign", campaign);
    }
</script>`
          }}
        />
      </div>
    );
  }
}

export default Header;
