import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  Route,
  NavLink,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import Homepage from "../src/Component/Homepage";
import HowjamaWorks from "../src/Component/HowjamaWorks";
// import * as serviceWorker from "./serviceWorker";
import Aboutus from "../src/Component/Aboutus";
import testimonial from "../src/Component/testimonial";
import StartWealthCreation from "./Component/StartWealthCreation";
import BuildWealthPrudence from "./Component/BuildWealthPrudence";
import IndiasEconomy from "./Component/IndiasEconomy";
import HowtoAlocate from "./Component/HowtoAlocate";
import SheerMagic from "./Component/SheerMagic";
import HowJamaWorksInfo from "./Component/HowJamaWorksInfo";
import startwealthcreationinfo from "./Component/startwealthcreationinfo";
import BuildWealthprudenceinfo from "./Component/BuildWealthprudenceinfo";
import Values from "./Component/Values";
import Investphilosphyinfo from "./Component/Investphilosphyinfo";
import IndiasEconomyinfo from "./Component/IndiasEconomyinfo";
import Jamaadvisorypage from "./Component/Jamaadvisorypage";
import InvestPhilosphy from "./Component/InvestPhilosphy";
import HowtoAlocateinfo from "./Component/HowtoAlocateinfo";
import SheerMagicinfo from "./Component/SheerMagicinfo";
import FiveTrillionEconomy from "./Component/fiveTrillionEconomy";
import HowToAllocateInfo from "./Component/howToAllocate";
import Pricing from "./Component/pricing/index";
import SheerMagicArticle from "./Component/sheerMagicArticle";
import ContactUs from "./Component/contactUs";
import Privacy from "./Component/privacy";
import Contact from "./Component/contact";
import Compare from "./Component/Compare";
import CompareNew from "./Component/CompareNew";
import CompareMF from "./Component/compareMF";
import CompareMF2 from "./Component/compareMFNew";
import SIP from "./Component/SIP";
import SIPNew from "./Component/SIPNew";
import Landingpage from "./Component/landingpage";
import ZoomRedirect from "./Component/zoom";
import CrisisComp from "./Component/CrisisCompare";
import CrisisComp2 from "./Component/CrisisCompareNew";
import Compare2 from "./Component/Compare2";
import ComparePerformance2 from "./Component/Compare2New";
import UpstoxRedirection from "./Component/upstoxRedirection";
import OpenUpstoxRedirection from "./Component/openUpstoxRedirection";
import ZerodhaRedirection from "./Component/zerodhaRedirection";
import OpenZerodhaRedirection from "./Component/openZerodhaRedirection";
import WebexRedirection from "./Component/webexRedirection";
import MeetRedirection from "./Component/meetRedirection";
import BlogRedirection from "./Component/blogRedirection";
import LinkedinRedirection from "./Component/linkedinRedirection";
import SparkCompare from "./Component/SparkCompare";
import SparkCompareMF from "./Component/SparkCompareMF";
import SparkCrisisCompare from "./Component/SparkCrisisCompare";
import SparkSIP from "./Component/SparkSIP";



import ThankYou from "./Component/ThankYou";

const RedirectToNewSite = ({ url }) => {
  useEffect(() => {
    window.location.replace(url || "https://www.jamawealth.com");
  }, []);
  return null;
};

const routing = (
  <Router>
    {/* <BrowserRouter> */}
    <Switch>
      <Route
        exact
        path="/"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/landingpage"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/HowjamaWorks"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/Aboutus"
        component={() => {
          return (
            <RedirectToNewSite url="https://www.jamawealth.com/about-us/" />
          );
        }}
      />
      <Route
        path="/testimonial"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/StartWealthCreation"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/BuildWealthPrudence"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/IndiasEconomy"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/HowtoAlocate"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/SheerMagic"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/HowJamaWorksInfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/startwealthcreationinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/BuildWealthprudenceinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/Values"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/Investphilosphyinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/IndiasEconomyinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/HowtoAlocateinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/Jamaadvisorypage"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/InvestPhilosphy"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/SheerMagicinfo"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/fiveTrillionEconomy"
        component={() => {
          return (
            <RedirectToNewSite url="https://jamawealth.com/blog/indias-5-trillion-economy/" />
          );
        }}
      />
      <Route
        path="/howToAllocate"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />

      <Route
        path="/pricing"
        component={() => {
          return (
            <RedirectToNewSite url="https://www.jamawealth.com/pricing/" />
          );
        }}
      />
      <Route
        path="/sheerMagicArticle"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/contactUs"
        component={() => {
          return (
            <RedirectToNewSite url="https://www.jamawealth.com/contact-us/" />
          );
        }}
      />
      <Route
        path="/thankYou"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/contact"
        component={() => {
          return (
            <RedirectToNewSite url="https://www.jamawealth.com/contact-us/" />
          );
        }}
      />
      <Route
        path="/privacy"
        component={() => {
          return (
            <RedirectToNewSite url="https://www.jamawealth.com/privacy-policy/" />
          );
        }}
      />
      <Route
        path="/compare1"
        component={() => {
          return (
            <RedirectToNewSite url="https://performance.jamawealth.com/JewelCompare" />
          );
        }}
      />
      <Route path="/JewelCompare" component={CompareNew} />
      <Route
        path="/compare-performance"
        component={() => {
          return <RedirectToNewSite url="https://www.jamawealth.com" />;
        }}
      />
      <Route
        path="/comparemf"
        component={() => {
          return (
            <RedirectToNewSite url="https://performance.jamawealth.com/comparemf2" />
          );
        }}
      />
      <Route
        path="/crisisCompare"
        component={() => {
          return (
            <RedirectToNewSite url="https://performance.jamawealth.com/crisiscompare2" />
          );
        }}
      />

      <Route path="/compare-performance2" component={ComparePerformance2} />
      <Route path="/comparemf2" component={CompareMF2} />
      <Route path="/crisisCompare2" component={CrisisComp2} />
      <Route
        path="/SIP"
        component={() => {
          return (
            <RedirectToNewSite url="https://performance.jamawealth.com/SIP2" />
          );
        }}
      />
      <Route path="/SIP2" component={SIPNew} />
      <Route path="/SparkCompare" component={SparkCompare} />
      <Route path="/SparkCompareMF" component={SparkCompareMF} />
      <Route path="/SparkCrisisCompare" component={SparkCrisisCompare} />
      <Route path="/SparkSIP" component={SparkSIP} />

      {/* <Route path="/crisisCompare/:crisis" component={CrisisComp} /> */}
      <Route path="/zoom" component={ZoomRedirect} />
      <Route path="/open-upstox" component={OpenUpstoxRedirection} />
      <Route path="/open-zerodha" component={OpenZerodhaRedirection} />
      <Route path="/zerodha" component={ZerodhaRedirection} />
      <Route path="/webex" component={WebexRedirection} />
      <Route path="/meet" component={MeetRedirection} />
      <Route path="/blog" component={BlogRedirection} />
      <Route path="/upstox" component={UpstoxRedirection} />
      <Route path="/li" component={LinkedinRedirection} />
      <Route path="/LI" component={LinkedinRedirection} />

      {/* Old code */}
      {/* <Route exact path="/" component={Homepage} />
      <Route path="/landingpage" component={Landingpage} />
      <Route path="/HowjamaWorks" component={HowjamaWorks} />
      <Route path="/Aboutus" component={Aboutus} />
      <Route path="/testimonial" component={testimonial} />
      <Route path="/StartWealthCreation" component={StartWealthCreation} />
      <Route path="/BuildWealthPrudence" component={BuildWealthPrudence} />
      <Route path="/IndiasEconomy" component={IndiasEconomy} />
      <Route path="/HowtoAlocate" component={HowtoAlocate} />
      <Route path="/SheerMagic" component={SheerMagic} />
      <Route path="/HowJamaWorksInfo" component={HowJamaWorksInfo} />
      <Route
        path="/startwealthcreationinfo"
        component={startwealthcreationinfo}
      />
      <Route
        path="/BuildWealthprudenceinfo"
        component={BuildWealthprudenceinfo}
      />
      <Route path="/Values" component={Values} />
      <Route path="/Investphilosphyinfo" component={Investphilosphyinfo} />
      <Route path="/IndiasEconomyinfo" component={IndiasEconomyinfo} />
      <Route path="/HowtoAlocateinfo" component={HowtoAlocateinfo} />
      <Route path="/Jamaadvisorypage" component={Jamaadvisorypage} />
      <Route path="/InvestPhilosphy" component={InvestPhilosphy} />
      <Route path="/SheerMagicinfo" component={SheerMagicinfo} />
      <Route path="/fiveTrillionEconomy" component={FiveTrillionEconomy} />
      <Route path="/howToAllocate" component={HowToAllocateInfo} />

      <Route path="/pricing" component={Pricing} />
      <Route path="/sheerMagicArticle" component={SheerMagicArticle} />
      <Route path="/contactUs" component={ContactUs} />
      <Route path="/thankYou" component={ThankYou} />
      <Route path="/contact" component={Contact} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/compare" component={Compare} />
      <Route path="/JewelCompare" component={CompareNew} />
      <Route path="/compare-performance" component={Compare2} />
      <Route path="/comparemf" component={CompareMF} />
      <Route path="/crisisCompare" component={CrisisComp} />

      <Route path="/compare-performance2" component={ComparePerformance2} />
      <Route path="/comparemf2" component={CompareMF2} />
      <Route path="/crisisCompare2" component={CrisisComp2} />
      <Route path="/SIP" component={SIP} />
      <Route path="/SIP2" component={SIPNew} />
      <Route path="/zoom" component={ZoomRedirect} />
      <Route path="/open-upstox" component={OpenUpstoxRedirection} />
      <Route path="/open-zerodha" component={OpenZerodhaRedirection} />
      <Route path="/zerodha" component={ZerodhaRedirection} />
      <Route path="/webex" component={WebexRedirection} />
      <Route path="/meet" component={MeetRedirection} />
      <Route path="/blog" component={BlogRedirection} />
      <Route path="/upstox" component={UpstoxRedirection} />
      <Route path="/li" component={LinkedinRedirection} />
      <Route path="/LI" component={LinkedinRedirection} /> */}
    </Switch>
    {/* </BrowserRouter> */}
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
